import React, { useState, useRef, useEffect } from "react";
import MyLink from "../MyLink/MyLink";
import "./Section.css";
import LoadingIndicator from "../Loading/LoadingIndicator";
// import TextToSpeech from "../Javascript/TextToSpeech";
import {
  doc,
  getDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import ReactQuill from "react-quill";
import DOMPurify from "dompurify";
import "react-quill/dist/quill.snow.css";
import { db } from "../../firebase";

const Section = ({
  articleId,
  header,
  coming,
  sectionTitle,
  day,
  month,
  year,
  readMore,
  customReadMoreLink,
  description,
  sectionDescription,
  img,
  instructor,
  places,
  openingHours,
  isAdmin,
  titlespan,
  sectionId,
  onSectionChange,
  projectId,
  isArticle,
  noDate,
  onUpdateArticle,
  onUpdateSection,
  onDeleteSection,
  isAbout,
  aboutId,
  canMoveUp,
  canMoveDown,
  moveUp,
  moveDown,
}) => {
  const days = Array.from({ length: 31 }, (_, i) => i + 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  // const monthIndex = months.indexOf(month);

  const [currentImageURL, setCurrentImageURL] = useState(img);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const sectionImageUploadRef = useRef(null);

  const [updatedFields, setUpdatedFields] = useState({
    header,
    description,
    day,
    month,
    year,
  });

  const [updatedSectionFields, setUpdatedSectionFields] = useState({
    sectionId,
    sectionTitle,
    sectionDescription,
  });

  const [confirmationText, setConfirmationText] = useState("");
  const [errorText, setErrorText] = useState("");

  useEffect(() => {
    const clearText = () => {
      setErrorText("");
      setConfirmationText("");
    };

    const timer = setTimeout(clearText, 3000);

    return () => clearTimeout(timer);
  }, [errorText, confirmationText]);

  useEffect(() => {
    setChangedSections([]); // Reset unsaved state when navigating to a new section
  }, [sectionId]);

  const updateArticleImage = async (id, imageUrl, newImagePath) => {
    console.log("handleImageUpload isArticle:", isArticle);
    const collection = isArticle
      ? "articles"
      : isAbout
      ? "aboutPages"
      : "whatWeDo";
    const docId = isArticle ? articleId : isAbout ? aboutId : projectId;
    console.log(`Doc parameters: collection=${collection}, docId=${docId}`);
    const docRef = doc(db, collection, docId);
    try {
      await updateDoc(docRef, {
        img: imageUrl,
        imgPath: newImagePath,
      });
      setCurrentImageURL(imageUrl);
    } catch (error) {
      console.error("Error updating image: ", error);
    }
  };

  const uploadImage = async (file, id, sectionId) => {
    console.log("handleImageUpload isArticle:", isArticle);
    console.log(
      `Starting uploadImage with id: ${id}, sectionId: ${sectionId}, isArticle: ${isArticle}`
    ); // Log the initial call parameters

    // Check if file is undefined
    if (!file) {
      console.error("No file provided for upload");
      return { error: "No file provided for upload" };
    }

    setIsLoading(true);
    const storage = getStorage();
    const collection = isArticle
      ? "articles"
      : isAbout
      ? "aboutPages"
      : "whatWeDo";

    const pathSegment = isArticle
      ? ""
      : sectionId
      ? `sections/${sectionId}`
      : "";

    const newImagePath = `${collection}/${id}/${file.name}`;

    console.log(`Constructed newImagePath: ${newImagePath}`); // Log the constructed image path

    const fileRef = storageRef(storage, newImagePath);

    try {
      await uploadBytes(fileRef, file);
      const downloadURL = await getDownloadURL(fileRef);
      console.log(`Upload successful. Download URL: ${downloadURL}`); // Log success and the download URL
      setIsLoading(false);
      return { downloadURL, newImagePath };
    } catch (error) {
      console.error("Error uploading file:", error);
      setErrorText("Error uploading file:", error);
      setIsLoading(false);
      return { error };
    }
  };

  const handleImageUpload = async (event) => {
    console.log("handleImageUpload isArticle:", isArticle);
    const file = event.target.files[0];
    if (!file || (!articleId && !projectId && !aboutId))
      setErrorText("No file or article/wwd id");

    if (file && file.size > 500 * 1024) {
      // 500kB limit
      const fileSizeInKB = Math.round(file.size / 1024);
      const errorMessage = `Image size exceeds 500kB limit. (${fileSizeInKB}kB)`;
      setErrorText(errorMessage);
      return;
    }

    console.log(
      `Uploading image ${
        sectionId
          ? "for section ID: " + sectionId
          : isArticle
          ? "for article ID: " + articleId
          : "for program ID: " + projectId
      }`
    );

    const collection = isArticle
      ? "articles"
      : isAbout
      ? "aboutPages"
      : "whatWeDo";
    const id = isArticle ? articleId : isAbout ? aboutId : projectId.toString();
    console.log("SELECTED COLLECTION", collection);
    const docRef = doc(db, collection, id);
    const docSnap = await getDoc(docRef);
    console.log("SELECTED id", id);
    if (docSnap.exists() && docSnap.data().imgPath) {
      const oldImagePath = docSnap.data().imgPath;
      const oldImageRef = storageRef(getStorage(), oldImagePath);
      deleteObject(oldImageRef)
        .then(() => {
          console.log("Previous image deleted successfully");
        })
        .catch((error) => {
          // Handle specific error cases here
          if (error.code === "storage/object-not-found") {
            // The file doesn't exist, log for informational purposes or handle as needed
            console.log(
              "Old image file does not exist, no deletion necessary."
            );
          } else {
            // Log or handle other errors as appropriate
            console.error("Error removing previous image: ", error);
          }
        });
    }

    const { downloadURL, newImagePath } = await uploadImage(
      file,
      id,
      sectionId,
      isArticle
    );
    if (downloadURL) {
      updateArticleImage(id, downloadURL, newImagePath, isArticle);
      setCurrentImageURL(downloadURL);
      setIsLoading(false);
    }
  };

  const handleSectionImageUpload = async (event, sectionId) => {
    console.log("Entering handleSectionImageUpload", {
      sectionId,
      isArticle,
      projectId,
      articleId,
    });

    const file = event.target.files[0];
    if (!file) {
      console.log("No file selected for upload.");
      return;
    }

    setIsLoading(true);

    const collection = isArticle
      ? "articles"
      : isAbout
      ? "aboutPages"
      : "whatWeDo";
    const docId = isArticle ? articleId : isAbout ? aboutId : projectId;
    const newImagePath = `${collection}/${docId}/sections/${sectionId}/${file.name}`;
    const fileRef = storageRef(getStorage(), newImagePath);

    try {
      // Upload the new image
      await uploadBytes(fileRef, file);
      const downloadURL = await getDownloadURL(fileRef);

      // Fetch the document to check for an existing image
      const docRef = doc(db, collection, docId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const sections = docSnap.data().sections || [];
        let oldImagePath = null;

        const updatedSections = sections.map((section) => {
          // Ensure section.section_id and sectionId are not undefined before calling .toString()
          if (
            section.section_id &&
            sectionId &&
            section.section_id.toString() === sectionId.toString()
          ) {
            oldImagePath = section.imgPath; // Capture the old image path
            return {
              ...section,
              section_image: downloadURL,
              imgPath: newImagePath,
            }; // Update with new image
          }
          return section;
        });

        // If there's an old image path and it's different from the new image path, attempt deletion
        if (oldImagePath && oldImagePath !== newImagePath) {
          const oldImageRef = storageRef(getStorage(), oldImagePath);
          await deleteObject(oldImageRef).catch((error) => {
            console.error("Error removing previous section image:", error);
          });
        }

        // Update the document with the new sections array
        await updateDoc(docRef, { sections: updatedSections });
      }

      setCurrentImageURL(downloadURL);
    } catch (error) {
      console.error("Error during section image upload:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateArticle = async () => {
    setIsUpdating(true);
    await onUpdateArticle(updatedFields);
    setConfirmationText("Article updated successfully!");
    setIsUpdating(false);
    setChangedSections((prevChangedSections) =>
      prevChangedSections.filter((id) => id !== sectionId)
    );
  };

  const handleUpdateSection = async () => {
    setIsUpdating(true);
    await onUpdateSection(updatedSectionFields);
    setConfirmationText("Section updated successfully!");
    setIsUpdating(false);
    setChangedSections((prevChangedSections) =>
      prevChangedSections.filter((id) => id !== sectionId)
    );
  };

  const handleDeleteSection = async () => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this section?"
    );
    if (confirmDelete) {
      setIsDeleting(true);
      await onDeleteSection();
      setConfirmationText("Section deleted successfully!");
      setIsDeleting(false);
    }
  };

  // Note: Ensure updateSectionImage and updateArticleImage functions correctly handle projectId.
  const [changedSections, setChangedSections] = useState([]);
  const renderAdminView = () => {
    const [originalFields, setOriginalFields] = useState({
      header,
      description,
      day,
      month,
      year,
    });

    const handleFieldChange = (fieldName, value) => {
      if (
        ["header", "description", "day", "month", "year"].includes(fieldName)
      ) {
        setUpdatedFields((prevState) => ({
          ...prevState,
          [fieldName]: value,
        }));

        // Check if the field has changed compared to the original value
        setChangedSections((prevChangedSections) => {
          const isChanged = value !== originalFields[fieldName];

          return isChanged
            ? [...new Set([...prevChangedSections, sectionId])] // Add only if not already present
            : prevChangedSections.filter((id) => id !== sectionId); // Remove if reverted
        });
      } else if (["sectionTitle", "sectionDescription"].includes(fieldName)) {
        if (!sectionId) {
          console.error("sectionId is required for updating sections.");
          return;
        }

        setUpdatedSectionFields((prevState) => ({
          ...prevState,
          [fieldName]: value,
        }));

        // Check if the field has changed compared to the original value
        setChangedSections((prevChangedSections) => {
          const isChanged = value !== originalFields[fieldName];

          return isChanged
            ? [...new Set([...prevChangedSections, sectionId])] // Add only if not already present
            : prevChangedSections.filter((id) => id !== sectionId); // Remove if reverted
        });
      } else {
        console.error(`Invalid field name: ${fieldName}`);
      }
    };

    useEffect(() => {
      // Update originalFields when any of the original fields change
      setOriginalFields({
        header,
        description,
        day,
        month,
        year,
      });
    }, [header, description, day, month, year]);

    const quillModules = {
      toolbar: [
        ["bold", "italic", "underline"],
        ["link", "blockquote"], // Link and Blockquote buttons
        ["clean"], // Remove formatting button
      ],
    };

    const quillFormats = ["bold", "italic", "underline", "link", "blockquote"];

    return (
      <div
        className={`section ${isAdmin ? "admin-section" : "border"} ${
          changedSections.includes(sectionId) ? "unsaved" : ""
        }`}
      >
        {confirmationText && (
          <div className="confirmation-message">
            <i class="fa-regular fa-circle-check"></i> {confirmationText}
          </div>
        )}
        {errorText && (
          <div className="error-message-2">
            <i class="fa-regular fa-face-frown-open"></i> {errorText}
          </div>
        )}
        <div className="flex-image-text">
          <div>
            <div>
              {header ? (
                <div>
                  <input
                    type="text"
                    value={updatedFields.header}
                    className="h2 headerInput"
                    onChange={(e) =>
                      handleFieldChange("header", e.target.value)
                    }
                  />
                  {coming && <span className="coming">{coming}</span>}
                  {!projectId && !aboutId && (
                    <div className="date dateInput">
                      <h5>
                        <i className="fa-regular fa-calendar"></i> Posted on{" "}
                        <select
                          value={updatedFields.month}
                          onChange={(e) =>
                            handleFieldChange("month", e.target.value)
                          }
                        >
                          {months.map((monthName, index) => (
                            <option key={index} value={monthName}>
                              {monthName}
                            </option>
                          ))}
                        </select>
                        <select
                          value={updatedFields.day}
                          onChange={(e) =>
                            handleFieldChange("day", e.target.value)
                          }
                        >
                          {days.map((day) => (
                            <option key={day} value={day}>
                              {day}
                            </option>
                          ))}
                        </select>
                        <input
                          type="text"
                          className="year"
                          value={updatedFields.year}
                          onChange={(e) =>
                            handleFieldChange("year", e.target.value)
                          }
                        />
                      </h5>
                    </div>
                  )}
                  <input
                    type="file"
                    id={`file-upload-article-${articleId}`}
                    style={{ display: "none" }}
                    onChange={handleImageUpload}
                  />
                  <label
                    htmlFor={`file-upload-article-${articleId}`}
                    className="file-upload-button header"
                  >
                    <i class="fa-regular fa-image"></i> Upload{" "}
                    <span className="hideOnPhone">Image</span>
                  </label>
                  <ReactQuill
                    theme="snow"
                    value={updatedFields.description}
                    onChange={(value) =>
                      handleFieldChange("description", value)
                    }
                    placeholder="Write your description here..."
                    modules={quillModules}
                    formats={quillFormats}
                    className="quill-input"
                  />
                  <span
                    onClick={() => handleUpdateArticle(articleId)}
                    className="div-readmore status edit"
                  >
                    Update section{" "}
                    {isUpdating ? (
                      <span className="rolling-loading-animation small blue"></span>
                    ) : (
                      <>
                        <i class="fa-regular fa-circle-check"></i>
                      </>
                    )}
                  </span>{" "}
                </div>
              ) : (
                <div>
                  <div className="h3-file-upload">
                    <input
                      type="text"
                      value={updatedSectionFields.sectionTitle || ""}
                      onChange={(e) =>
                        handleFieldChange("sectionTitle", e.target.value)
                      }
                      className="h3 titleInput"
                      placeholder="Write a title"
                    />
                    <input
                      type="file"
                      id={`file-upload-${sectionId}`}
                      ref={sectionImageUploadRef}
                      style={{ display: "none" }}
                      onChange={(e) => handleSectionImageUpload(e, sectionId)}
                    />
                    <label
                      htmlFor={`file-upload-${sectionId}`}
                      className="file-upload-button section"
                    >
                      <i class="fa-regular fa-image"></i> Upload{" "}
                      <span className="hideOnPhone">Image</span>
                    </label>
                    {isAdmin && (
                      <>
                        <ReactQuill
                          theme="snow"
                          value={updatedSectionFields.sectionDescription}
                          onChange={(value) =>
                            handleFieldChange("sectionDescription", value)
                          }
                          placeholder="Write section description here..."
                          modules={quillModules}
                          formats={quillFormats}
                          className="quill-input"
                        />
                        <span
                          onClick={() => handleUpdateSection(sectionId)}
                          className="div-readmore status edit"
                        >
                          Update section{" "}
                          {isUpdating ? (
                            <span className="rolling-loading-animation small blue"></span>
                          ) : (
                            <>
                              <i className="fa-regular fa-circle-check"></i>
                            </>
                          )}
                        </span>{" "}
                        {isAdmin && sectionId && (
                          <span className="move-buttons">
                            {canMoveUp && (
                              <button
                                onClick={moveUp}
                                className="move-button up"
                              >
                                <i className="fa-solid fa-arrow-up"></i> Up
                              </button>
                            )}
                            {canMoveDown && (
                              <button
                                onClick={moveDown}
                                className="move-button down"
                              >
                                <i className="fa-solid fa-arrow-down"></i> Down
                              </button>
                            )}
                          </span>
                        )}
                        <span
                          onClick={() => handleDeleteSection(sectionId)}
                          className="div-readmore status edit lessSize"
                        >
                          Delete section{" "}
                          {isDeleting ? (
                            <span className="rolling-loading-animation small"></span>
                          ) : (
                            <>
                              <i class="fa-regular fa-circle-xmark"></i>
                            </>
                          )}
                        </span>{" "}
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>

            {readMore && (
              <div>
                <MyLink to={customReadMoreLink || `/article/${articleId}`}>
                  <div className="div-readmore">
                    <p className="readmore">
                      {readMore} <i className="fa-solid fa-arrow-right"></i>
                    </p>
                  </div>
                </MyLink>
              </div>
            )}
          </div>
          {isLoading ? (
            <LoadingIndicator />
          ) : (
            <img
              src={currentImageURL}
              className="img"
              alt={header || sectionTitle || "Image"}
            />
          )}
        </div>
        {!readMore && <br />}
      </div>
    );
  };

  const renderUserView = () => {
    return (
      <div className="section">
        <div className="flex-image-text">
          <div>
            <div className="flex-container-2 subheader">
              {header ? (
                <>
                  <div className="header-h2">
                    <h2>{header}</h2>{" "}
                  </div>

                  {!projectId && !noDate && !aboutId && (
                    <h5>
                      <div className="date">
                        <i className="fa-regular fa-calendar"></i>
                        Posted on {month} {day}, {year}
                      </div>
                    </h5>
                  )}

                  {coming && <span className="coming">{coming}</span>}
                  <div
                    className="section-description"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(description),
                    }}
                  />
                </>
              ) : null}

              {!header || isAdmin ? (
                <>
                  {sectionTitle ? (
                    <>
                      <h3 className="section-h3">{sectionTitle}</h3>
                      <span>{titlespan}</span>
                    </>
                  ) : null}
                  <div
                    className="section-sectionDescription"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(sectionDescription),
                    }}
                  />
                </>
              ) : null}
            </div>

            {readMore && (
              <div>
                <MyLink to={customReadMoreLink || `/article/${articleId}`}>
                  <div className="div-readmore">
                    <p className="readmore">
                      {readMore} <i className="fa-solid fa-arrow-right"></i>
                    </p>
                  </div>
                </MyLink>
              </div>
            )}
          </div>
          {isLoading ? (
            <LoadingIndicator />
          ) : (
            <img
              src={currentImageURL}
              className="img"
              alt={header || sectionTitle || "Image"}
            />
          )}
        </div>
        {!readMore && <br />}
      </div>
    );
  };

  return isAdmin ? renderAdminView() : renderUserView();
};

export default Section;
