import React, { useState, useEffect } from "react";
import {
  getFirestore,
  collection,
  getDocs,
  getDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import Loading from "../../../../components/Loading/Loading";
import { useAuth } from "../../../../AuthProvider";
import "./Remarks.css"; // Add your custom CSS

const Remarks = () => {
  const { isAdmin, userData } = useAuth();
  const [classes, setClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState(
    localStorage.getItem("selectedClass") || ""
  );
  const [students, setStudents] = useState([]);
  const [filteredStudent, setFilteredStudent] = useState("");
  const [lessons, setLessons] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [remarks, setRemarks] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [newRemark, setNewRemark] = useState({
    studentId: "",
    date: "",
    lessonId: "",
    category: "",
    comment: "",
  });
  const [editRemark, setEditRemark] = useState(null); // State for editing a remark
  const [loading, setLoading] = useState(true);
  const [subjectsLoading, setSubjectsLoading] = useState(true);
  const [showFollowupPopup, setShowFollowupPopup] = useState(false);
  const [selectedRemarkForFollowup, setSelectedRemarkForFollowup] =
    useState(null);
  const [newFollowup, setNewFollowup] = useState("");

  const fetchRemarks = async (classId) => {
    try {
      const db = getFirestore();
      const classDoc = await getDoc(doc(db, "classes", classId));
      if (classDoc.exists()) {
        const classData = classDoc.data();
        setRemarks(classData.remarks || {});
      }
    } catch (error) {
      console.error("Error fetching remarks:", error);
    }
  };

  const fetchClasses = async () => {
    try {
      const db = getFirestore();
      const classesCollection = collection(db, "classes");
      const classSnapshot = await getDocs(classesCollection);
      const currentYear = new Date().getFullYear(); // Get current year

      const classesData = classSnapshot.docs
        .map((doc) => ({
          id: doc.id,
          name: `Grade ${doc.data().grade} - ${doc.data().year}`, // Construct a class name if needed
          ...doc.data(),
        }))
        .filter((cls) => cls.year === currentYear); // Filter by current year

      setClasses(classesData);
      setLoading(false); // Update loading state
    } catch (error) {
      console.error("Error fetching classes:", error);
      setLoading(false);
    }
  };

  const [categories, setCategories] = useState([]);

  const fetchCategories = async () => {
    try {
      const db = getFirestore();
      const categoriesCollection = collection(db, "remarks-categories");
      const categoriesSnapshot = await getDocs(categoriesCollection);
      const categoriesData = categoriesSnapshot.docs.map((doc) => ({
        id: doc.id,
        label: doc.data().name,
        description: doc.data().description,
      }));
      setCategories(categoriesData);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchSubjects = async () => {
    try {
      const db = getFirestore();
      const subjectsCollection = collection(db, "subjects");
      const subjectsSnapshot = await getDocs(subjectsCollection);
      const subjectsData = subjectsSnapshot.docs.map((doc) => ({
        id: doc.id,
        name: doc.data().name,
      }));
      setSubjects(subjectsData);
      setSubjectsLoading(false);
    } catch (error) {
      console.error("Error fetching subjects:", error);
      setSubjectsLoading(false);
    }
  };

  useEffect(() => {
    fetchClasses();
    fetchSubjects();
  }, []);

  // Modified fetchStudents: fetch student documents from the "students" collection using the IDs stored in the class document.
  const fetchStudents = async (classId) => {
    try {
      const db = getFirestore();
      const classDoc = await getDoc(doc(db, "classes", classId));
      if (classDoc.exists()) {
        const classData = classDoc.data();
        const studentIds = (classData.students || []).map((student) =>
          typeof student === "object" ? student.id : student
        );
        const studentDocs = await Promise.all(
          studentIds.map((studentId) => getDoc(doc(db, "students", studentId)))
        );
        const studentsData = studentDocs
          .filter((docSnap) => docSnap.exists())
          .map((docSnap) => ({
            id: docSnap.id,
            ...docSnap.data(),
          }));
        setStudents(studentsData);
      }
    } catch (error) {
      console.error("Error fetching students:", error);
    }
  };

  const fetchLessonsForToday = async (classId) => {
    if (subjectsLoading) return;
    const db = getFirestore();
    const classDoc = await getDoc(doc(db, "classes", classId));
    if (classDoc.exists()) {
      const classData = classDoc.data();
      const todayDate = new Date().toISOString().split("T")[0];
      const todayLessons = classData.lessons?.[todayDate] || [];

      const enrichedLessons = todayLessons.map((lesson) => {
        const subject = subjects.find((sub) => sub.id === lesson.subjectId);
        return {
          ...lesson,
          subjectName: subject ? subject.name : "Unknown Subject",
        };
      });

      setLessons(enrichedLessons);
    }
  };

  const handleSaveRemark = async () => {
    const { studentId, date, lessonId, category, comment } = newRemark;

    if (!studentId || !date || !lessonId || !category || !comment) {
      alert("Please fill in all fields.");
      return;
    }

    const db = getFirestore();
    const classDocRef = doc(db, "classes", selectedClass);
    const classDoc = await getDoc(classDocRef);

    if (!classDoc.exists()) {
      alert("Class not found.");
      return;
    }

    const classData = classDoc.data();
    const updatedRemarks = classData.remarks || {};

    const remarkData = {
      lessonId,
      category,
      comment,
      createdBy: userData.name,
    };

    if (!updatedRemarks[date]) {
      updatedRemarks[date] = [];
    }

    const studentRemarks = updatedRemarks[date].find(
      (remark) => remark.studentId === studentId
    );

    if (studentRemarks) {
      studentRemarks.remarks.push(remarkData);
    } else {
      updatedRemarks[date].push({
        studentId,
        remarks: [remarkData],
      });
    }

    await updateDoc(classDocRef, { remarks: updatedRemarks });

    alert("Remark saved successfully.");

    await fetchRemarks(selectedClass);

    setShowPopup(false);
    setNewRemark({
      studentId: "",
      date: "",
      lessonId: "",
      category: "",
      comment: "",
    });
  };

  useEffect(() => {
    if (selectedClass && !subjectsLoading) {
      fetchStudents(selectedClass);
      fetchLessonsForToday(selectedClass);
      fetchRemarks(selectedClass);
    }
  }, [selectedClass, subjectsLoading]);

  useEffect(() => {
    if (newRemark.date && selectedClass) {
      fetchLessonsForDate(selectedClass, newRemark.date);
    }
  }, [newRemark.date, selectedClass]);

  useEffect(() => {
    if (showPopup) {
      const today = new Date().toISOString().split("T")[0];
      setNewRemark((prev) => ({
        ...prev,
        date: today,
      }));
      if (selectedClass) fetchLessonsForDate(selectedClass, today);
    }
  }, [showPopup, selectedClass]);

  const fetchLessonsForDate = async (classId, date) => {
    try {
      const db = getFirestore();
      const classDoc = await getDoc(doc(db, "classes", classId));
      if (classDoc.exists()) {
        const classData = classDoc.data();
        const selectedDateLessons = classData.lessons?.[date] || [];

        const enrichedLessons = selectedDateLessons.map((lesson) => {
          const subject = subjects.find((sub) => sub.id === lesson.subjectId);
          return {
            ...lesson,
            subjectName: subject ? subject.name : "Unknown Subject",
          };
        });

        setLessons(enrichedLessons);
      }
    } catch (error) {
      console.error("Error fetching lessons for date:", error);
    }
  };

  const handleSaveFollowup = async () => {
    if (!selectedRemarkForFollowup || !newFollowup) {
      alert("Please enter the follow-up details.");
      return;
    }

    const { date, studentId, index } = selectedRemarkForFollowup;

    try {
      const db = getFirestore();
      const classDocRef = doc(db, "classes", selectedClass);
      const classDoc = await getDoc(classDocRef);

      if (!classDoc.exists()) {
        alert("Class not found.");
        return;
      }

      const classData = classDoc.data();
      const updatedRemarks = { ...classData.remarks };

      if (updatedRemarks[date]) {
        const studentRemarks = updatedRemarks[date].find(
          (remark) => remark.studentId === studentId
        );

        if (studentRemarks && studentRemarks.remarks[index]) {
          if (!studentRemarks.remarks[index].followups) {
            studentRemarks.remarks[index].followups = [];
          }
          studentRemarks.remarks[index].followups.push({
            followup: newFollowup,
            createdBy: userData.name,
            date: new Date().toISOString(),
          });
        }
      }

      await updateDoc(classDocRef, { remarks: updatedRemarks });
      alert("Follow-up added successfully.");
      setRemarks(updatedRemarks);
      setShowFollowupPopup(false);
      setNewFollowup("");
    } catch (error) {
      console.error("Error saving follow-up:", error);
      alert("Failed to save follow-up. Please try again.");
    }
  };

  const renderFollowupPopup = () => {
    if (!selectedRemarkForFollowup) return null;

    return (
      <div className="popup-overlay">
        <div className="popup-content">
          <h3>Register Follow-up</h3>
          <table className="form-table">
            <tbody>
              <tr>
                <textarea
                  placeholder="What action was taken?"
                  rows="5"
                  cols="40"
                  value={newFollowup}
                  onChange={(e) => setNewFollowup(e.target.value)}
                />
              </tr>
            </tbody>
          </table>

          <div className="popup-buttons">
            <button
              className="popup-create-button"
              onClick={handleSaveFollowup}
            >
              Save Follow-up
            </button>
            <button
              className="popup-cancel-button"
              onClick={() => setShowFollowupPopup(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderPopup = () => {
    const selectedCategory = categories.find(
      (cat) => cat.label === newRemark.category
    );

    return (
      <div className="popup-overlay">
        <div className="popup-content">
          <h3>Add Remark</h3>
          <table className="form-table">
            <tbody>
              <tr>
                <td>
                  <label>Student:</label>
                </td>
                <td>
                  <select
                    value={newRemark.studentId}
                    onChange={(e) =>
                      setNewRemark({ ...newRemark, studentId: e.target.value })
                    }
                  >
                    <option value="">Select Student</option>
                    {students.map((student) => (
                      <option key={student.id} value={student.id}>
                        {student.name}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
              <tr>
                <td>
                  <label>Date:</label>
                </td>
                <td>
                  <input
                    type="date"
                    value={newRemark.date}
                    onChange={(e) => {
                      const selectedDate = e.target.value;
                      setNewRemark({ ...newRemark, date: selectedDate });
                      if (selectedClass)
                        fetchLessonsForDate(selectedClass, selectedDate);
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <label>Lesson:</label>
                </td>
                <td>
                  <select
                    value={newRemark.lessonId}
                    onChange={(e) =>
                      setNewRemark({ ...newRemark, lessonId: e.target.value })
                    }
                  >
                    <option value="">Select Lesson</option>
                    <option value="none">No associated lesson</option>
                    {lessons.map((lesson) => (
                      <option key={lesson.lessonId} value={lesson.lessonId}>
                        {lesson.subjectName} ({lesson.startTime} -{" "}
                        {lesson.endTime})
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
              <tr>
                <td>
                  <label>Category:</label>
                </td>
                <td>
                  <select
                    value={newRemark.category}
                    onChange={(e) =>
                      setNewRemark({ ...newRemark, category: e.target.value })
                    }
                  >
                    <option value="">Select Category</option>
                    {categories.map((cat) => (
                      <option key={cat.label} value={cat.label}>
                        {cat.label}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
              {selectedCategory && (
                <tr>
                  <td colSpan="2" className="category-description">
                    <i className="fa-regular fa-circle-question"></i>{" "}
                    <span>{selectedCategory.description}</span>
                  </td>
                </tr>
              )}
              <tr>
                <td colSpan="2">
                  <textarea
                    placeholder="What happened?"
                    className="remarks-textarea"
                    rows="8"
                    cols="30"
                    value={newRemark.comment}
                    onChange={(e) =>
                      setNewRemark({ ...newRemark, comment: e.target.value })
                    }
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <div className="popup-buttons">
            <button className="popup-create-button" onClick={handleSaveRemark}>
              Save
            </button>
            <button
              className="popup-cancel-button"
              onClick={() => setShowPopup(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderEditPopup = () => {
    if (!editRemark) return null;

    const handleSaveEdit = async () => {
      const { date, studentId, index, lessonId, category, comment } =
        editRemark;

      if (!date || !studentId || !category || !comment) {
        alert("Please fill in all fields.");
        return;
      }

      try {
        const db = getFirestore();
        const classDocRef = doc(db, "classes", selectedClass);
        const classDoc = await getDoc(classDocRef);

        if (!classDoc.exists()) {
          alert("Class not found.");
          return;
        }

        const classData = classDoc.data();
        const updatedRemarks = { ...classData.remarks };

        if (updatedRemarks[date]) {
          const studentRemarks = updatedRemarks[date].find(
            (remark) => remark.studentId === studentId
          );

          if (studentRemarks && studentRemarks.remarks[index]) {
            studentRemarks.remarks[index] = {
              ...studentRemarks.remarks[index],
              lessonId,
              category,
              comment,
            };
          }
        }

        await updateDoc(classDocRef, { remarks: updatedRemarks });

        alert("Remark updated successfully.");
        await fetchRemarks(selectedClass);
        setEditRemark(null);
      } catch (error) {
        console.error("Error updating remark:", error);
        alert("Failed to update the remark. Please try again.");
      }
    };

    return (
      <div className="popup-overlay">
        <div className="popup-content">
          <h3>Edit Remark</h3>
          <table className="form-table">
            <tbody>
              <tr>
                <td>
                  <label>Lesson:</label>
                </td>
                <td>
                  <select
                    value={editRemark.lessonId}
                    onChange={(e) =>
                      setEditRemark((prev) => ({
                        ...prev,
                        lessonId: e.target.value,
                      }))
                    }
                  >
                    <option value="">Select Lesson</option>
                    <option value="none">No associated lesson</option>
                    {lessons.map((lesson) => (
                      <option key={lesson.lessonId} value={lesson.lessonId}>
                        {lesson.subjectName} ({lesson.startTime} -{" "}
                        {lesson.endTime})
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
              <tr>
                <td>
                  <label>Category:</label>
                </td>
                <td>
                  <select
                    value={editRemark.category}
                    onChange={(e) =>
                      setEditRemark((prev) => ({
                        ...prev,
                        category: e.target.value,
                      }))
                    }
                  >
                    <option value="">Select Category</option>
                    {categories.map((cat) => (
                      <option key={cat.label} value={cat.label}>
                        {cat.label}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <textarea
                    placeholder="What happened?"
                    className="remarks-textarea"
                    rows="8"
                    cols="30"
                    value={editRemark.comment}
                    onChange={(e) =>
                      setEditRemark((prev) => ({
                        ...prev,
                        comment: e.target.value,
                      }))
                    }
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <div className="popup-buttons">
            <button className="popup-create-button" onClick={handleSaveEdit}>
              Save
            </button>
            <button
              className="popup-cancel-button"
              onClick={() => setEditRemark(null)}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderRemarks = () => {
    if (!remarks || Object.keys(remarks).length === 0) {
      return (
        <div>
          <br />
          No remarks available for this class.
        </div>
      );
    }

    const sortedRemarks = Object.entries(remarks).sort(([dateA], [dateB]) => {
      return new Date(dateB) - new Date(dateA);
    });

    const handleDeleteRemark = async (date, studentId, remarkIndex) => {
      if (!window.confirm("Are you sure you want to delete this remark?")) {
        return;
      }

      try {
        const db = getFirestore();
        const classDocRef = doc(db, "classes", selectedClass);
        const classDoc = await getDoc(classDocRef);

        if (classDoc.exists()) {
          const classData = classDoc.data();
          const updatedRemarks = { ...classData.remarks };

          if (updatedRemarks[date]) {
            const studentRemarksIndex = updatedRemarks[date].findIndex(
              (remark) => remark.studentId === studentId
            );

            if (studentRemarksIndex > -1) {
              updatedRemarks[date][studentRemarksIndex].remarks.splice(
                remarkIndex,
                1
              );

              if (
                updatedRemarks[date][studentRemarksIndex].remarks.length === 0
              ) {
                updatedRemarks[date].splice(studentRemarksIndex, 1);
              }

              if (updatedRemarks[date].length === 0) {
                delete updatedRemarks[date];
              }
            }
          }

          await updateDoc(classDocRef, { remarks: updatedRemarks });
          setRemarks(updatedRemarks);
          alert("Remark deleted successfully.");
        }
      } catch (error) {
        console.error("Error deleting remark:", error);
        alert("Failed to delete the remark. Please try again.");
      }
    };

    const handleDeleteFollowup = async (
      date,
      studentId,
      remarkIndex,
      followupIndex
    ) => {
      if (!window.confirm("Are you sure you want to delete this follow-up?")) {
        return;
      }

      try {
        const db = getFirestore();
        const classDocRef = doc(db, "classes", selectedClass);
        const classDoc = await getDoc(classDocRef);

        if (classDoc.exists()) {
          const classData = classDoc.data();
          const updatedRemarks = { ...classData.remarks };

          if (updatedRemarks[date]) {
            const studentRemarksIndex = updatedRemarks[date].findIndex(
              (remark) => remark.studentId === studentId
            );

            if (studentRemarksIndex > -1) {
              const followups =
                updatedRemarks[date][studentRemarksIndex].remarks[remarkIndex]
                  .followups;

              if (followups && followups.length > 0) {
                followups.splice(followupIndex, 1);

                if (followups.length === 0) {
                  delete updatedRemarks[date][studentRemarksIndex].remarks[
                    remarkIndex
                  ].followups;
                }

                await updateDoc(classDocRef, { remarks: updatedRemarks });
                setRemarks(updatedRemarks);
                alert("Follow-up deleted successfully.");
              }
            }
          }
        }
      } catch (error) {
        console.error("Error deleting follow-up:", error);
        alert("Failed to delete the follow-up. Please try again.");
      }
    };

    return (
      <div className="remarks-table">
        <table>
          <thead>
            <tr>
              <th>Date & Lesson</th>
              <th colSpan="2">What happened?</th>
              <th colSpan="2">Follow-up</th>
            </tr>
          </thead>
          <tbody>
            {sortedRemarks.map(([date, dailyRemarks]) =>
              dailyRemarks
                .filter(
                  (remark) =>
                    !filteredStudent || remark.studentId === filteredStudent
                )
                .map((remark) => {
                  // Fetch student name by looking up the student document using the id
                  const student = students.find(
                    (s) => s.id === remark.studentId
                  ) || {
                    name: "Unknown Student",
                  };

                  return remark.remarks.map((r, index) => {
                    const lessonName =
                      r.lessonId === "none"
                        ? "No associated lesson"
                        : lessons.find((l) => l.lessonId === r.lessonId)
                        ? `${
                            lessons.find((l) => l.lessonId === r.lessonId)
                              ?.subjectName || "Unknown"
                          }`
                        : "Unknown";

                    const formattedDate = new Intl.DateTimeFormat("en-US", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    }).format(new Date(date));

                    return (
                      <tr key={`${date}-${remark.studentId}-${index}`}>
                        <td className="remark-info">
                          <span>
                            <i className="fa-regular fa-clock"></i>{" "}
                            {formattedDate}
                          </span>
                          <span>
                            <i className="fa-solid fa-person-chalkboard"></i>{" "}
                            {lessonName}
                          </span>
                          <span>
                            <i className="fa-solid fa-child"></i>{" "}
                            <b>{student.name}</b>
                          </span>
                          <span>
                            <i className="fa-solid fa-triangle-exclamation"></i>{" "}
                            {r.category}
                          </span>
                        </td>

                        <td colSpan="2">
                          {isAdmin && (
                            <button
                              onClick={() =>
                                handleDeleteRemark(
                                  date,
                                  remark.studentId,
                                  index
                                )
                              }
                              className="remark-button red"
                            >
                              Delete Remark
                            </button>
                          )}{" "}
                          {r.createdBy === userData.name && (
                            <button
                              onClick={() =>
                                setEditRemark({
                                  date,
                                  studentId: remark.studentId,
                                  index,
                                  ...r,
                                })
                              }
                              className="remark-button blue"
                            >
                              Edit Remark
                            </button>
                          )}{" "}
                          <b>By {r.createdBy || "Unknown"}: </b>"{r.comment}"
                        </td>

                        <td colSpan="2" className="remark-info">
                          {r.followups && r.followups.length > 0 ? (
                            <>
                              {r.followups.map((f, i) => (
                                <span key={i}>
                                  {isAdmin && (
                                    <button
                                      onClick={() =>
                                        handleDeleteFollowup(
                                          date,
                                          remark.studentId,
                                          index,
                                          i
                                        )
                                      }
                                      className="remark-button red"
                                    >
                                      Delete Follow-up
                                    </button>
                                  )}{" "}
                                  <b>
                                    By {f.createdBy} on{" "}
                                    {new Date(f.date).toLocaleDateString()}:{" "}
                                  </b>{" "}
                                  "{f.followup}"{" "}
                                </span>
                              ))}
                            </>
                          ) : (
                            "No follow-ups registered"
                          )}
                          <br />
                          <br />
                          <button
                            onClick={() => {
                              setSelectedRemarkForFollowup({
                                date,
                                studentId: remark.studentId,
                                index,
                              });
                              setShowFollowupPopup(true);
                            }}
                            className="remark-button add"
                          >
                            Add Follow-up
                          </button>
                        </td>
                      </tr>
                    );
                  });
                })
            )}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="remarks-container">
      <h2>Remarks</h2>
      {loading || subjectsLoading ? (
        <>
          <Loading />
          <br />
        </>
      ) : (
        <>
          <div className="class-selector">
            <p>
              <label>Select Class: </label>
              <select
                value={selectedClass}
                onChange={(e) => {
                  setSelectedClass(e.target.value);
                  localStorage.setItem("selectedClass", e.target.value);
                }}
              >
                <option value="">Select Class</option>
                {classes.map((cls) => (
                  <option key={cls.id} value={cls.id}>
                    {cls.name || `Class ${cls.id}`}
                  </option>
                ))}
              </select>

              {selectedClass && (
                <>
                  {" "}
                  <label>Filter by Student: </label>
                  <select
                    value={filteredStudent}
                    onChange={(e) => setFilteredStudent(e.target.value)}
                  >
                    <option value="">All Students</option>
                    {students.map((student) => (
                      <option key={student.id} value={student.id}>
                        {student.name}
                      </option>
                    ))}
                  </select>
                </>
              )}
            </p>
          </div>
          <br />
          {selectedClass && (
            <>
              <button
                className="popup-create-button"
                onClick={() => setShowPopup(true)}
              >
                <i className="fa-solid fa-plus"></i> Add Remark
              </button>

              <div className="remarks-view">{renderRemarks()}</div>
            </>
          )}
          {showPopup && renderPopup()}
          {showFollowupPopup && renderFollowupPopup()}
          {renderEditPopup()}
        </>
      )}
    </div>
  );
};

export default Remarks;
