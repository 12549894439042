import React, { useState, useEffect } from "react";
import {
  getFirestore,
  collection,
  getDocs,
  addDoc,
  doc,
  setDoc,
  deleteDoc,
  updateDoc,
  query,
  where,
} from "firebase/firestore";
import Locationscroll from "../../../../components/Locationscroll/Locationscroll";
import { Loading } from "../../../../components";
import ManageUsers from "./ManageUsers";
import { useAuth } from "../../../../AuthProvider";
import IncomeExpenses from "./IncomeExpenses";
import "./Sponsorships.css";

const Sponsorships = () => {
  const { isAdmin, userData } = useAuth();
  const [grades, setGrades] = useState([]);
  const [students, setStudents] = useState([]);
  const [users, setUsers] = useState([]);
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [editedRows, setEditedRows] = useState({});
  const [lastUpdatedSponsor, setLastUpdatedSponsor] = useState(null);
  const [sponsors, setSponsors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [newSponsorship, setNewSponsorship] = useState({
    sponsorId: "",
    studentId: "",
    commitmentAmount: "",
    commitmentPeriod: "",
    currency: "NOK",
    startDate: "",
    status: "Pending",
  });
  const [edits, setEdits] = useState({});
  const [showCanceled, setShowCanceled] = useState(true);
  const [selectedGrade, setSelectedGrade] = useState(null);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [selectedSponsor, setSelectedSponsor] = useState(null);
  const [commitmentDetails, setCommitmentDetails] = useState({
    amount: "",
    period: "",
    currency: "NOK",
    startDate: "",
  });
  const [step, setStep] = useState(1);
  const [editMode, setEditMode] = useState(false);
  const [showStudentOptions, setShowStudentOptions] = useState(false);
  const [sortOption, setSortOption] = useState(() => {
    return localStorage.getItem("sponsorshipsSortOption") || "";
  });
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [selectedSponsorship, setSelectedSponsorship] = useState(null);
  const [sponsorStatuses, setSponsorStatuses] = useState({});

  const db = getFirestore();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const usersSnapshot = await getDocs(collection(db, "users"));
        const studentsSnapshot = await getDocs(collection(db, "students"));
        const sponsorshipsSnapshot = await getDocs(
          collection(db, "sponsorships")
        );

        setUsers(
          usersSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
        );
        setStudents(
          studentsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
        );
        setSponsors(
          sponsorshipsSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }))
        );
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchStudents = async () => {
      try {
        const db = getFirestore();
        const studentsSnapshot = await getDocs(collection(db, "students"));
        const studentsData = studentsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setStudents(studentsData);
      } catch (error) {
        console.error("Error fetching students:", error);
      }
    };

    fetchStudents();
  }, []);

  useEffect(() => {
    if (lastUpdatedSponsor) {
      alert(`Sponsorship updated successfully!`);
    }
  }, [lastUpdatedSponsor]);

  const refreshUsers = async () => {
    try {
      const usersSnapshot = await getDocs(collection(db, "users"));
      const usersData = usersSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setUsers(usersData);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    const checkSponsorStatuses = async () => {
      const statuses = {};

      for (const student of students) {
        const isSponsored = await hasSponsor(student.id);
        statuses[student.id] = isSponsored;
      }

      setSponsorStatuses(statuses);
    };

    if (students.length > 0) {
      checkSponsorStatuses();
    }
  }, [students]);

  const toggleShowCanceled = () => {
    setShowCanceled((prev) => !prev);
  };

  const markRowAsEdited = (sponsorId) => {
    setEditedRows((prevEditedRows) => ({
      ...prevEditedRows,
      [sponsorId]: true,
    }));
  };

  useEffect(() => {
    // Fetch students and their sponsor statuses
    const fetchStudentsAndStatuses = async () => {
      try {
        const studentsSnapshot = await getDocs(collection(db, "students"));
        const studentsData = studentsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setStudents(studentsData);

        // Fetch sponsor statuses for all students
        const statuses = {};
        for (const student of studentsData) {
          statuses[student.id] = await hasSponsor(student.id);
        }
        setSponsorStatuses(statuses);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching students or sponsor statuses:", error);
        setLoading(false);
      }
    };

    fetchStudentsAndStatuses();
  }, []);

  const hasSponsor = async (studentId) => {
    try {
      const student = students.find((s) => s.id === studentId);
      if (!student) return false;

      const sponsorshipQuery = query(
        collection(db, "sponsorships"),
        where("studentName", "==", student.name),
        where("status", "==", "Active")
      );
      const querySnapshot = await getDocs(sponsorshipQuery);
      return !querySnapshot.empty; // Return true if at least one record exists
    } catch (error) {
      console.error("Error checking sponsorship status:", error);
      return false;
    }
  };

  const handleEditSponsorship = async (sponsorship) => {
    const student = students.find(
      (student) => student.name === sponsorship.studentName
    );

    // Check if the student has an active sponsorship
    const isSponsored = student ? await hasSponsor(student.id) : false;

    setSelectedSponsorship({
      ...sponsorship,
      studentId: student?.id || null,
      isSponsored, // Add sponsorship status
    });

    setShowEditPopup(true);
  };

  const refreshSponsorships = async () => {
    try {
      const sponsorshipsSnapshot = await getDocs(
        collection(db, "sponsorships")
      );
      const sponsorshipsData = sponsorshipsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setSponsors(sponsorshipsData);
    } catch (error) {
      console.error("Error refreshing sponsorships:", error);
    }
  };

  const handleSaveSponsorship = async () => {
    const {
      sponsorId,
      studentId,
      commitmentAmount,
      commitmentPeriod,
      currency,
      startDate,
      status,
    } = newSponsorship;

    if (!sponsorId || !commitmentAmount || !commitmentPeriod || !currency) {
      alert("Please fill in all required fields.");
      return;
    }

    const selectedStudent = students.find((s) => s.id === studentId);

    try {
      await addDoc(collection(db, "sponsorships"), {
        sponsorId,
        studentId: studentId || null,
        studentName: selectedStudent ? selectedStudent.name : null,
        commitmentAmount,
        commitmentPeriod,
        currency,
        startDate: startDate || null,
        status,
        includeInMonthlyIncome: status === "Active", // automatically include if Active
      });

      alert("Sponsorship saved successfully.");
      setShowPopup(false);
      setNewSponsorship({
        sponsorId: "",
        studentId: "",
        commitmentAmount: "",
        commitmentPeriod: "",
        currency: "NOK",
        startDate: "",
        status: "Pending",
      });
      refreshSponsorships();
    } catch (error) {
      console.error("Error saving sponsorship:", error);
      alert("Failed to save sponsorship. Please try again.");
    }
  };

  const handleDelete = async (sponsorshipId) => {
    if (!window.confirm("Are you sure you want to delete this sponsorship?")) {
      return;
    }

    try {
      await deleteDoc(doc(db, "sponsorships", sponsorshipId));
      setSponsors((prevSponsors) =>
        prevSponsors.filter((sponsor) => sponsor.id !== sponsorshipId)
      );
      alert("Sponsorship deleted successfully.");
      showPopup(false);
    } catch (error) {
      console.error("Error deleting sponsorship:", error);
      alert("Failed to delete sponsorship. Please try again.");
    }
  };

  const handleSave = async () => {
    try {
      const updatedSponsorship = { ...selectedSponsorship };

      if (updatedSponsorship.status === "Canceled") {
        updatedSponsorship.isSponsored = false;
      }

      if (updatedSponsorship.studentId) {
        const selectedStudent = students.find(
          (s) => s.id === updatedSponsorship.studentId
        );
        updatedSponsorship.studentName = selectedStudent
          ? selectedStudent.name
          : "";
      }

      const sponsorDoc = doc(db, "sponsorships", updatedSponsorship.id);
      await updateDoc(sponsorDoc, updatedSponsorship);

      setSponsors((prevSponsors) =>
        prevSponsors.map((sp) =>
          sp.id === updatedSponsorship.id ? updatedSponsorship : sp
        )
      );

      setShowEditPopup(false);
      alert("Sponsorship updated successfully!");
      refreshSponsorships();
    } catch (error) {
      console.error("Error updating sponsorship:", error);
      alert("Failed to update sponsorship. Please try again.");
    }
  };

  const getStatusStyle = (status) => {
    switch (status) {
      case "Active":
        return {
          color: "#5cb85c",
          fontWeight: "bold",
        };
      case "Pending":
        return {
          color: "#f0ad4e",
          fontWeight: "bold",
        };
    }
  };

  const renderPopup = () => (
    <div className="popup-overlay">
      <div className="popup-content">
        <h3>Assign Sponsorship</h3>
        <table className="form-table">
          <tbody>
            <tr>
              <td>
                <label>Sponsor:</label>
              </td>
              <td>
                <select
                  value={newSponsorship.sponsorId}
                  onChange={(e) =>
                    setNewSponsorship({
                      ...newSponsorship,
                      sponsorId: e.target.value,
                    })
                  }
                >
                  <option value="">Select Sponsor</option>
                  {users.map((user) => (
                    <option key={user.id} value={user.id}>
                      {user.name || user.phoneNumber}
                    </option>
                  ))}
                </select>
              </td>
            </tr>
            <tr>
              <td>
                <label>Student:</label>
              </td>
              <td>
                <select
                  value={newSponsorship.studentId}
                  onChange={(e) =>
                    setNewSponsorship({
                      ...newSponsorship,
                      studentId: e.target.value,
                    })
                  }
                >
                  <option value="">No Student</option>
                  {students
                    .sort((a, b) => {
                      const isSponsoredA = sponsorStatuses[a.id] || false;
                      const isSponsoredB = sponsorStatuses[b.id] || false;
                      return isSponsoredA === isSponsoredB
                        ? 0
                        : isSponsoredA
                        ? 1
                        : -1;
                    })
                    .map((student) => {
                      const isSponsored = sponsorStatuses[student.id]; // Check sponsor status
                      return (
                        <option
                          key={student.id}
                          value={student.id}
                          style={{
                            color: isSponsored ? "red" : "black", // Highlight sponsored students
                          }}
                        >
                          {student.name} - Grade {student.grade || "N/A"}{" "}
                          {isSponsored ? "(has sponsor)" : ""}
                        </option>
                      );
                    })}
                </select>
              </td>
            </tr>
            <tr>
              <td>
                <label>Currency:</label>
              </td>
              <td>
                <select
                  value={newSponsorship.currency}
                  onChange={(e) =>
                    setNewSponsorship({
                      ...newSponsorship,
                      currency: e.target.value,
                    })
                  }
                >
                  <option value="NOK">NOK</option>
                  <option value="KES">KES</option>
                  <option value="USD">USD</option>
                </select>
                <input
                  type="number"
                  placeholder="Amount"
                  value={newSponsorship.commitmentAmount}
                  onChange={(e) =>
                    setNewSponsorship({
                      ...newSponsorship,
                      commitmentAmount: e.target.value,
                    })
                  }
                />
              </td>
            </tr>

            <tr>
              <td>
                <label>Interval:</label>
              </td>
              <td>
                <input
                  type="number"
                  placeholder="Write 1 for monthly"
                  value={newSponsorship.commitmentPeriod}
                  onChange={(e) =>
                    setNewSponsorship({
                      ...newSponsorship,
                      commitmentPeriod: e.target.value,
                    })
                  }
                />
              </td>
            </tr>

            <tr>
              <td>
                <label>Start Date:</label>
              </td>
              <td>
                <input
                  type="date"
                  value={newSponsorship.startDate}
                  onChange={(e) =>
                    setNewSponsorship({
                      ...newSponsorship,
                      startDate: e.target.value,
                    })
                  }
                />
              </td>
            </tr>
            <tr>
              <td>
                <label>Status:</label>
              </td>
              <td>
                <select
                  value={newSponsorship.status}
                  onChange={(e) =>
                    setNewSponsorship({
                      ...newSponsorship,
                      status: e.target.value,
                    })
                  }
                >
                  <option value="Pending">Pending</option>
                  <option value="Active">Active</option>
                  <option value="Canceled">Canceled</option>
                </select>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="popup-buttons">
          <button
            className="popup-create-button"
            onClick={handleSaveSponsorship}
          >
            Save
          </button>
          <button
            className="popup-cancel-button"
            onClick={() => {
              setNewSponsorship({
                sponsorId: "",
                studentId: "",
                commitmentAmount: "",
                commitmentPeriod: "",
                currency: "NOK",
                startDate: "",
                status: "Pending",
              });
              setShowPopup(false);
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <Locationscroll />
      <div className="row-2">
        <div className="main">
          {showEditPopup && selectedSponsorship && (
            <div className="edit-popup">
              <div className="popup-content">
                <h3>Edit Sponsorship</h3>
                <table className="edit-sponsorship-table">
                  <tbody>
                    <tr>
                      <td>
                        <label>Sponsor</label>
                      </td>
                      <td>
                        <select
                          value={selectedSponsorship.sponsorId || ""}
                          onChange={(e) =>
                            setSelectedSponsorship({
                              ...selectedSponsorship,
                              sponsorId: e.target.value,
                            })
                          }
                        >
                          {users.map((user) => (
                            <option key={user.id} value={user.id}>
                              {user.name || user.phoneNumber}
                            </option>
                          ))}
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label>Student</label>
                      </td>
                      <td>
                        <select
                          value={selectedSponsorship.studentId || ""}
                          onChange={(e) => {
                            const studentId = e.target.value;
                            const selectedStudent = students.find(
                              (s) => s.id === studentId
                            );
                            setSelectedSponsorship({
                              ...selectedSponsorship,
                              studentId,
                              studentName: selectedStudent
                                ? selectedStudent.name
                                : "",
                            });
                          }}
                        >
                          <option value="">No student</option>
                          {[...students]
                            .filter(
                              (student) => student.grade !== "Exmatriculated"
                            )
                            .sort((a, b) => {
                              const isSponsoredA =
                                sponsorStatuses[a.id] || false;
                              const isSponsoredB =
                                sponsorStatuses[b.id] || false;
                              return isSponsoredA === isSponsoredB
                                ? 0
                                : isSponsoredA
                                ? 1
                                : -1;
                            })
                            .map((student) => {
                              const isSponsored = sponsorStatuses[student.id];
                              return (
                                <option
                                  key={student.id}
                                  value={student.id}
                                  style={{
                                    color: isSponsored ? "red" : "black",
                                  }}
                                >
                                  {student.name} | Grade {student.grade}{" "}
                                  {isSponsored ? "(has sponsor)" : ""}
                                </option>
                              );
                            })}
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label>Amount</label>
                      </td>
                      <td>
                        <input
                          type="number"
                          value={selectedSponsorship.commitmentAmount || ""}
                          onChange={(e) =>
                            setSelectedSponsorship({
                              ...selectedSponsorship,
                              commitmentAmount: e.target.value,
                            })
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label>Period</label>
                      </td>
                      <td>
                        <input
                          type="number"
                          value={selectedSponsorship.commitmentPeriod || ""}
                          onChange={(e) =>
                            setSelectedSponsorship({
                              ...selectedSponsorship,
                              commitmentPeriod: e.target.value,
                            })
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label>Payments On Day</label>
                      </td>
                      <td>
                        <input
                          type="number"
                          value={selectedSponsorship.paymentDay || ""}
                          onChange={(e) =>
                            setSelectedSponsorship({
                              ...selectedSponsorship,
                              paymentDay: e.target.value,
                            })
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label>Start Date</label>
                      </td>
                      <td>
                        <input
                          type="date"
                          value={selectedSponsorship.startDate || ""}
                          onChange={(e) =>
                            setSelectedSponsorship({
                              ...selectedSponsorship,
                              startDate: e.target.value,
                            })
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label>Monthly Calculation</label>
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          checked={!!selectedSponsorship.includeInMonthlyIncome}
                          onChange={(e) =>
                            setSelectedSponsorship({
                              ...selectedSponsorship,
                              includeInMonthlyIncome: e.target.checked,
                            })
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label>Status</label>
                      </td>
                      <td>
                        <select
                          value={selectedSponsorship.status || ""}
                          onChange={(e) =>
                            setSelectedSponsorship({
                              ...selectedSponsorship,
                              status: e.target.value,
                            })
                          }
                        >
                          <option value="Pending">Pending</option>
                          <option value="Active">Active</option>
                          <option value="Canceled">Canceled</option>
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td>Delete</td>
                      <td>
                        {userData &&
                          userData.email === "magnus@thewatotoprogram.org" && (
                            <span
                              style={{ marginLeft: "8px", color: "red" }}
                              className="popup-delete-button"
                              onClick={() =>
                                handleDelete(selectedSponsorship.id)
                              }
                            >
                              x
                            </span>
                          )}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <div className="popup-buttons">
                  <button
                    className="popup-create-button"
                    onClick={() => {
                      handleSave(selectedSponsorship);
                      setShowEditPopup(false);
                    }}
                  >
                    Save
                  </button>
                  <button
                    className="popup-cancel-button"
                    onClick={() => {
                      setShowEditPopup(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          )}
          <div className="sponsorship-page">
            <h2>Sponsorships</h2>

            {loading ? (
              <Loading />
            ) : (
              <>
                <button
                  className="popup-create-button"
                  onClick={() => setShowPopup(true)}
                >
                  <i class="fa-solid fa-plus"></i> Assign Sponsorship
                </button>

                <br />
                <br />
                <h3>Add / Update users</h3>
                <div className="manage-users-parent">
                  <ManageUsers refreshUsers={refreshUsers} />
                </div>
                <br />

                {showPopup && renderPopup()}

                <br />
                <h3>Current Sponsorships</h3>
                <IncomeExpenses sponsors={sponsors} />
                <br />

                <div className="sort-buttons">
                  <button onClick={() => setSortOption("amount")}>
                    Sort by Amount
                  </button>
                  <button onClick={() => setSortOption("paymentDay")}>
                    Sort by Payment Day
                  </button>
                  <button onClick={() => setSortOption("startDate")}>
                    Sort by Start Date
                  </button>
                  <button onClick={toggleShowCanceled}>
                    {showCanceled
                      ? "Hide Canceled Sponsorships"
                      : "Show Canceled Sponsorships"}
                  </button>
                </div>

                <table className="current-sponsorships-table">
                  <thead>
                    <tr>
                      <th>Sponsor</th>
                      <th>Student</th>
                      <th>Amount</th>
                      <th>Monthly Interval</th>
                      <th>Payments On Day</th>
                      <th>Start Date</th>
                      <th>Monthly calculation</th>
                      <th>Status</th>
                      <th>Edit</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sponsors
                      .filter((sponsor) => {
                        if (showCanceled) {
                          return true; // Show all sponsorships, including canceled ones
                        }
                        return (
                          sponsor.status !== "Canceled" ||
                          editedRows[sponsor.id]
                        );
                      })
                      .sort((a, b) => {
                        // First, move Pending sponsors to the top
                        if (a.status === "Pending" && b.status !== "Pending")
                          return -1;
                        if (a.status !== "Pending" && b.status === "Pending")
                          return 1;

                        // Then sort according to sortOption
                        if (sortOption === "amount") {
                          return (
                            (parseFloat(a.commitmentAmount) || 0) -
                            (parseFloat(b.commitmentAmount) || 0)
                          );
                        } else if (sortOption === "paymentDay") {
                          return (
                            (parseInt(a.paymentDay) || 0) -
                            (parseInt(b.paymentDay) || 0)
                          );
                        } else if (sortOption === "startDate") {
                          const dateA = new Date(a.startDate);
                          const dateB = new Date(b.startDate);
                          return dateA - dateB;
                        } else {
                          return 0; // No additional sorting
                        }
                      })

                      .map((sponsor, index) => {
                        const studentName =
                          students.find(
                            (student) => student.id === sponsor.studentId
                          )?.name ||
                          sponsor.studentName ||
                          "";

                        return (
                          <tr
                            key={sponsor.id}
                            className={
                              editedRows[sponsor.id] ? "edited-row" : ""
                            }
                          >
                            <td>
                              {users.find(
                                (user) => user.id === sponsor.sponsorId
                              )?.name || "Unknown"}
                            </td>

                            <td>{sponsor.studentName}</td>

                            <td>
                              {sponsor.currency} {sponsor.commitmentAmount}
                            </td>

                            <td>{sponsor.commitmentPeriod || "N/A"}</td>

                            <td>{sponsor.paymentDay || "N/A"}</td>

                            <td>{sponsor.startDate || "N/A"}</td>
                            <td>
                              {sponsor.includeInMonthlyIncome ? (
                                <i class="fa-solid fa-check"></i>
                              ) : (
                                "Not included"
                              )}
                            </td>

                            <td>
                              {
                                <span style={getStatusStyle(sponsor.status)}>
                                  {sponsor.status}
                                </span>
                              }
                            </td>

                            <td>
                              <button
                                className="remark-button blue"
                                onClick={() => handleEditSponsorship(sponsor)}
                              >
                                Edit
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Sponsorships;
