import React, { useState, useRef } from "react";
import { Navbar, Footer, Side, MyLink } from "../../../components";
import { images } from "../../../constants";
import { useNavigate } from "react-router-dom";
import Locationscroll from "../../../components/Locationscroll/Locationscroll";
import VippsRecurring from "./VippsRecurring";
import "./Support.css";
import SupportStepForm from "./SupportStepForm";

const Support = ({ isAdmin }) => {
  const [activeOption, setActiveOption] = useState("one-time");
  const navigate = useNavigate();

  const handleOptionClick = (option) => {
    setActiveOption(option);
  };

  return (
    <div>
      <Locationscroll />
      <Navbar isAdmin={isAdmin} />
      <div className="row" id="flex">
        {/* <Side /> */}
        <div className="main max-1000">
          <div className="article">
            <div className="flex-image-text">
              <div>
                <h2>Support</h2>
                <p>
                  We greatly appreciate any donations coming in that will help
                  better the lives of children in Kibera.
                </p>
                <p>Asante sana - Thank you very much.</p>
                <p>
                  100% of your donation goes directly to its purpose. Our only
                  expenses are transaction fees.
                </p>
              </div>
              <img src={images.kidLooking} className="img" alt="Kid looking" />
            </div>
          </div>

          {/* <br />

          <div className="article-click goToNews">
            <div onClick={() => navigate("/fundraiser")}>
              {" "}
              <p>
                <i class="fa-solid fa-hand-holding-heart"></i> Check out our
                current fundraiser <i class="fa-solid fa-arrow-right"></i>
              </p>
            </div>
          </div> */}

          <br />

          <div className="article-click goToNews">
            <div onClick={() => navigate("/sponsorship")}>
              {" "}
              <p>
                <i class="fa-solid fa-child"></i> Meet the children in need of a
                monthly sponsor <i class="fa-solid fa-arrow-right"></i>
              </p>
            </div>
          </div>
          <br />

          <div className="article">
            <SupportStepForm />
          </div>

          <br />

          {activeOption === "one-time" && (
            <div className="article">
              <h3>Bank Transfer</h3>
              <p>
                Name: The Watoto Youth Community And Library Norway
                <br />
                Account number: 1506 93 25022
              </p>
              <p>
                Electronic/paper IBAN: NO55 1506 9325 022
                <br />
                Address: DNB Bank ASA, Postboks 1600 Sentrum, 0021 Oslo
                <br />
                BIC: DNBANOKKXXX | Swift: DNBANOKK
              </p>
            </div>
          )}

          {activeOption === "our-shop" && (
            <div className="article">
              <h3>Our shop</h3>
              <p>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Culpa
                eum eligendi aspernatur perferendis at ipsum asperiores voluptas
                reprehenderit voluptate quam. At eveniet debitis amet quia
                deserunt aut in asperiores mollitia?
              </p>
            </div>
          )}

          <br />

          <div className="article" id="legal">
            <h3>Legal</h3>
            <p>
              The Watoto Program is registered in Kenya as a CBO, org. nr.
              KBR/CBO/5/4/2023(439).
            </p>

            <p>
              The Watoto Program is registered in Norway under{" "}
              <u>
                <a
                  href="https://w2.brreg.no/enhet/sok/detalj.jsp?orgnr=931464817"
                  target="_blank"
                >
                  The Watoto Youth Community And Library Norway
                </a>
              </u>
              , org. nr. 931 464 817.
            </p>

            <p>
              For Norwegian sales terms,{" "}
              <MyLink to="/support/termsofsale">
                <u>
                  <a>click here</a>
                </u>
              </MyLink>
              .
            </p>
            <p>
              To end monthly, recurring payments, kindly get in touch with us at{" "}
              <u>
                <a href="mailto:magnus@thewatotoprogram.org">
                  magnus@thewatotoprogram.org
                </a>
              </u>
              .
              <br />
              All recurring payments are of course not binding and can be
              terminated at your discretion.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Support;
