// About.jsx
import React, { useState } from "react";
import { Navbar, Footer, MyLink } from "../../../components";
import { images } from "../../../constants";
import Locationscroll from "../../../components/Locationscroll/Locationscroll";
import { useAbout } from "./AboutContext"; // Context for about data

const About = ({ isAdmin }) => {
  const { aboutItems, aboutLoading, addAbout } = useAbout();

  const [newAboutUrl, setNewAboutUrl] = useState("");

  const handleCreateAbout = () => {
    const url = prompt(
      "Enter the URL for the new about page.\n \n(thewatotoprogram.org/about/theurl)"
    );
    if (url) {
      setNewAboutUrl(url);
      createNewAbout(url);
    }
  };

  const createNewAbout = async (url) => {
    const newAboutData = {
      title: "New About Title",
      description: "Description of this new about page.",
      status: "Draft",
      img: "",
      url: url, // same idea as with Projects
    };

    try {
      await addAbout(newAboutData);
    } catch (error) {
      console.error("Error creating new about entry:", error);
    }
  };

  return (
    <div>
      <Locationscroll />
      <Navbar isAdmin={isAdmin} />
      <div className="max1600">
        <div className="article programsTxt">
          <div className="flex-image-text">
            <div>
              <h2>About Us</h2>
              <p>
                The Watoto Program works to better the lives of children in
                Kibera by providing free access to a place where kids can come
                in and read books, do their school assignments, or get free
                academic assistance and materials. Our work is soley run by
                private funds and sponsors.
              </p>
              <p>
                Kibera is the largest urban slum in Africa. Around 2,000,000
                people live here in what is defined as extreme poverty, earning
                less than US$2 per day. Unemployment rates are high and most
                people cannot afford education for their children.
              </p>
            </div>
            <img src={images.insideTheLibrary} className="img" alt="" />
          </div>
        </div>
        <div className="flex-container" id="flex">
          <div className="row2">
            {aboutLoading ? (
              <div className="loading-container">
                <div className="loading-animation"></div>
                <div className="loading-text">Loading about pages...</div>
              </div>
            ) : (
              <>
                {aboutItems
                  .sort((a, b) => {
                    if (a.status === b.status) return 0;
                    // Put Published before anything else:
                    if (a.status === "Published" && b.status !== "Published")
                      return -1;
                    // Otherwise push non-Published last:
                    return 1;
                  })

                  .map((item) => (
                    <div className="column-2" key={item.id}>
                      {item.status === "Published" || isAdmin ? (
                        <MyLink to={`/about/${item.url}`}>
                          <div className="program-card card" id={item.url}>
                            <div className="flex-container-2">
                              <h3>{item.title}</h3>

                              {item.status === "Draft" && isAdmin && (
                                <span className="coming">coming</span>
                              )}
                            </div>
                            <img
                              src={item.img}
                              className="program-img"
                              alt={item.title}
                            />
                          </div>
                        </MyLink>
                      ) : (
                        <div className="program-card card" id={item.url}>
                          <div className="flex-container-2">
                            <h3>{item.title}</h3>
                            <span className="coming">coming</span>
                          </div>
                          <img
                            src={item.img}
                            className="program-img"
                            alt={item.title}
                          />
                        </div>
                      )}
                    </div>
                  ))}

                {isAdmin && (
                  <div className="column-2">
                    <div
                      className="program-card card"
                      onClick={handleCreateAbout}
                      style={{ cursor: "pointer" }}
                    >
                      <div className="flex-container-2">
                        <h3>
                          <i className="fa-solid fa-plus"></i> Create New About
                        </h3>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default About;
