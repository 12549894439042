import React, { useState, useEffect, useContext } from "react";
import { Navigate } from "react-router-dom";
import { Navbar, Footer } from "../../../../components";
import Locationscroll from "../../../../components/Locationscroll/Locationscroll";
import { useAuth } from "../../../../AuthProvider";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../../../../firebase";
import Reports from "../Reports/Reports";
import StudentGrades from "../Grades/StudentGrades";
import AdminSettings from "../Admin/AdminSettings";
import StudentAttendance from "../Attendance/StudentAttendance";
import Overview from "./Overview";
import Students from "../Students/Students";
import AllStudents from "../AllStudents/AllStudents";
import Remarks from "../Remarks/Remarks";
import Economics from "../Economics/Economics";
import Budget from "../Budget/Budget";
import Sponsorships from "../Sponsorships/Sponsorships";
import "./Intranet.css";
import { PageCountsContext } from "./PageCountsContext";
import { Loading } from "../../../../components";

const Intranet = () => {
  const { isAdmin, userData } = useAuth();
  const [activePage, setActivePage] = useState("overview");
  const [intranetAccess, setIntranetAccess] = useState([]);
  const [loading, setLoading] = useState(true);
  const pageCounts = useContext(PageCountsContext);

  const pages = [
    { key: "overview", label: "Overview", icon: "fa-house" },
    { key: "reports", label: "Reports", icon: "fa-folder-open" },
    { key: "economics", label: "Economics", icon: "fa-coins" },
    { key: "budget", label: "Budget", icon: "fa-coins" },
    { key: "students", label: "Students", icon: "fa-child" },
    { key: "allstudents", label: "All students", icon: "fa-child" },
    { key: "remarks", label: "Remarks", icon: "fa-triangle-exclamation" },
    {
      key: "studentattendance",
      label: "Attendance",
      icon: "fa-clipboard-user",
    },
    { key: "studentgrades", label: "Grades", icon: "fa-graduation-cap" },
    {
      key: "sponsorships",
      label: "Sponsorships",
      icon: "fa-hand-holding-heart",
    },
    { key: "adminsettings", label: "Admin", icon: "fa-screwdriver-wrench" },
  ];

  useEffect(() => {
    const fetchUserAccess = async () => {
      if (!userData || !userData.email) return;
      const staffCollection = collection(db, "staff");
      const staffQuery = query(
        staffCollection,
        where("email", "==", userData.email)
      );
      const staffSnapshot = await getDocs(staffQuery);
      if (staffSnapshot.docs.length > 0) {
        const staffData = staffSnapshot.docs[0].data();
        setIntranetAccess(staffData.intranet_access || []);
      }
      setLoading(false);
    };

    fetchUserAccess();
  }, [userData, isAdmin]);

  const updateActivePage = (page) => {
    setActivePage(page);
    window.location.hash = page;
    localStorage.setItem("lastPage", page);
  };

  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash.substring(1);
      setActivePage(hash || "overview");
    };

    const lastPage = localStorage.getItem("lastPage");
    if (lastPage) {
      setActivePage(lastPage);
      window.location.hash = lastPage;
    } else {
      handleHashChange();
    }

    window.addEventListener("hashchange", handleHashChange);
    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  const renderPage = () => {
    switch (activePage) {
      case "overview":
        return <Overview />;
      case "reports":
        return <Reports />;
      case "studentattendance":
        return <StudentAttendance />;
      case "studentgrades":
        return <StudentGrades />;
      case "students":
        return <Students />;
      case "allstudents":
        return <AllStudents />;
      case "remarks":
        return <Remarks />;
      case "economics":
        return <Economics />;
      case "budget":
        return <Budget />;
      case "sponsorships":
        return <Sponsorships />;
      case "adminsettings":
        return <AdminSettings />;
      default:
        return <Overview />;
    }
  };

  return (
    <>
      <div>
        <Locationscroll />
        <Navbar isAdmin={isAdmin} />

        <div className="intranet-navigation">
          {loading ? (
            <button className="nav-button">
              <Loading />
            </button>
          ) : (
            pages
              .filter(
                (page) =>
                  intranetAccess.includes(page.key) ||
                  (isAdmin && page.key === "adminsettings")
              )
              .map((page) => (
                <button
                  key={page.key}
                  className={`nav-button ${
                    activePage === page.key ? "active" : ""
                  }`}
                  onClick={() => updateActivePage(page.key)}
                >
                  <i className={`fa-solid ${page.icon}`}></i> {page.label}
                  {pageCounts[page.key] > 0 && (
                    <span className="request-count">
                      {pageCounts[page.key]}
                    </span>
                  )}
                </button>
              ))
          )}
        </div>

        <div className="row2" id="flex">
          <div className="main">
            <div className="intranet-content">{renderPage()}</div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Intranet;
