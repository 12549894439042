import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import { Navbar, Footer, MyLink } from "../../../components";
import { images } from "../../../constants";
import Locationscroll from "../../../components/Locationscroll/Locationscroll";
import { useProjects } from "./ProjectsContext";

const Projects = ({ isAdmin }) => {
  const { projects, projectsLoading, addProject } = useProjects();
  const [project, setProject] = useState(null);
  const [isUpdated, setIsUpdated] = useState(false);
  const [newProgramUrl, setNewProgramUrl] = useState("");

  const handleCreateProgram = () => {
    const url = prompt(
      "Enter the URL for the new program.\n \n(thewatotolibrary.org/theurl)"
    );
    if (url) {
      setNewProgramUrl(url);
      createNewProgram(url);
    }
  };

  const createNewProgram = async (url) => {
    const newProgramData = {
      title: "New Program Title",
      description: "Description of the new program.",
      status: "Draft",
      img: "", // Assuming default image URL
      url: url, // Set the URL for the new program
    };

    try {
      await addProject(newProgramData);
    } catch (error) {
      console.error("Error creating new program:", error);
    }
  };

  return (
    <div>
      <Locationscroll />
      <Navbar isAdmin={isAdmin} />
      <div className="max1600">
        {" "}
        <div className="article programsTxt">
          <div className="flex-image-text">
            <div>
              <h2>Our Projects</h2>
              <div className="flex-container-2">
                <div className="openhrs">
                  <div>
                    <p>
                      <i class="fa-solid fa-school"></i> School opening hours
                    </p>
                  </div>
                </div>
                <div className="flex-container-2">
                  <div className="when">
                    <i
                      className="fa-regular fa-calendar"
                      aria-hidden="true"
                    ></i>{" "}
                    Mon - Fri: 8am - 4pm
                  </div>
                  <div className="when">
                    <i
                      className="fa-regular fa-calendar"
                      aria-hidden="true"
                    ></i>
                    Sat: 8am - 12am
                  </div>
                </div>
              </div>{" "}
              <div className="flex-container-2">
                <div className="openhrs">
                  <div>
                    <p>
                      <i class="fa-solid fa-book-open-reader"></i> Library
                      opening hours
                    </p>
                  </div>
                </div>
                <div className="flex-container-2">
                  <div className="when">
                    <i
                      className="fa-regular fa-calendar"
                      aria-hidden="true"
                    ></i>{" "}
                    Mon - Fri: 5pm - 9pm
                  </div>
                  <div className="when">
                    <i
                      className="fa-regular fa-calendar"
                      aria-hidden="true"
                    ></i>
                    Sat: 10am - 9pm
                  </div>
                </div>
              </div>
              <p>
                We inspire, empower, and facilitate childhood growth in areas
                faced with poverty and oppression. <br />
                We offer studying, dancing, computer classes, and chess
                regularly. You can read more about our other engagements in our
                newsletters.
              </p>
              <MyLink to="/articles">
                <div className="div-readmore">
                  <p className="allNewsLetters">
                    All newsletters{" "}
                    <i
                      className="fa-solid fa-arrow-right"
                      aria-hidden="true"
                    ></i>
                  </p>
                </div>
              </MyLink>
            </div>
            <img src={images.happyBoys} className="img" alt="" />
          </div>
        </div>
        <div className="flex-container" id="flex">
          <div className="row2">
            {projectsLoading ? (
              <div className="loading-container">
                <div className="loading-animation"></div>
                <div className="loading-text">Loading programs...</div>
              </div>
            ) : (
              <>
                {projects
                  .filter(
                    (program) => program.status === "Published" || isAdmin
                  )
                  .map((program) => (
                    <div className="column-2" key={program.id}>
                      {program.url === "thewatotoacademy" ? (
                        <MyLink to="/school">
                          <div className="program-card card" id={program.url}>
                            <div className="flex-container-2">
                              <h3>{program.title}</h3>
                            </div>
                            <img
                              src={program.img}
                              className="program-img"
                              alt={program.title}
                            />
                          </div>
                        </MyLink>
                      ) : (
                        <MyLink to={`/${program.url}`}>
                          <div className="program-card card" id={program.url}>
                            <div className="flex-container-2">
                              <h3>{program.title}</h3>
                              {isAdmin && program.status === "Draft" && (
                                <span className={`status-draft projects`}>
                                  {program.status}{" "}
                                  <i className="fa fa-pencil-alt"></i>
                                </span>
                              )}
                            </div>
                            <img
                              src={program.img}
                              className="program-img"
                              alt={program.title}
                            />
                          </div>
                        </MyLink>
                      )}
                    </div>
                  ))}

                {isAdmin && (
                  <div className="column-2">
                    <div
                      className="program-card card"
                      onClick={handleCreateProgram}
                      style={{ cursor: "pointer" }}
                    >
                      <div className="flex-container-2">
                        <h3>
                          <i className="fa-solid fa-plus"></i> Create New
                          Program
                        </h3>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Projects;
