// PAGES IMAGES
import logoTransparent from '../assets/logo/logo-transparent.png';
import logo from '../assets/logo/logo.png';
import boysPlayingChess from '../assets/chess/boys-playing-chess.png';
import happyBoys from '../assets/whatwedo/happy-boys.jpeg';
import insideTheLibrary from '../assets/about/inside-the-library.jpg';
import workingTogether from '../assets/about/working-together.jpeg';
import happyChildren from '../assets/about/happyChildren.JPG';
import kiberaKidBlack from '../assets/home/kibera-kid-black.png';
import cover from '../assets/home/cover-photo.jpeg'
import coverMobile from '../assets/home/cover-photo-mobile.jpeg';


// ABOUT US - General
import team from '../assets/about/team.jpeg'


// ABOUT US - Kibera
import kibera1 from '../assets/about/kibera/whereWeOperate.jpeg';
import kibera2 from '../assets/about/kibera/whoWeAreEngagingWith.jpeg';
import kibera3 from '../assets/about/kibera/lifeOfAKiberaChild.jpeg';
import kibera4 from '../assets/about/kibera/sufferingInSilence.jpeg';
import kibera5 from '../assets/about/kibera/schoolSituation.jpeg';
import kibera6 from '../assets/about/kibera/kiberaWindow.png';

// ABOUT US - Founder
import founder1 from '../assets/about/founder/BrandonAndKids.jpeg';
import founder2 from '../assets/about/founder/BrandonAndKids2.jpeg';
import founder3 from '../assets/about/founder/brandonAndKids3.jpeg';
import founder4 from '../assets/about/founder/BrandonInTanzania.jpeg';
import founder5 from '../assets/about/founder/BrandonInTWL.JPG';
import founder6 from '../assets/about/founder/BrandonAndKids4.JPG';
import founder7 from '../assets/about/founder/Magnus.png';
import founder8 from '../assets/about/founder/MagnusChess.jpg';
import founder9 from '../assets/about/founder/oloongongot.jpeg'
import founder10 from '../assets/about/founder/magnus_village.JPG'
import founder11 from '../assets/about/founder/christmas.jpg'
import founder12 from '../assets/about/founder/brandon_and_magnus.JPG'
import founder13 from '../assets/about/founder/funnyfunny.jpg'

// ABOUT US - Publications
import publications1 from '../assets/about/publications/publications1.jpeg'

// ABOUT US - Partners
import partners1 from '../assets/about/partners/partners1.JPG'

// Get in touch - PARTNERS
import opsahlGruppen from '../assets/donate/partners/opsahlgruppen.png'
import skullerudSkole from '../assets/donate/partners/skullerud-logo.png'
import wwqa from '../assets/donate/partners/wwqa.png'
import youcanyole from '../assets/donate/partners/youcanyole.png'
import sos from '../assets/donate/partners/sos.png'

// DONATE
import vippsLogo from '../assets/donate/vipps.png';
import paypalLogo from '../assets/donate/paypal-transparent.png';
import donorBoxLogo from '../assets/donate/donorbox-logo.png';
import scanVipps from '../assets/donate/scanVipps.png';
import kidLooking from '../assets/donate/kidLooking.jpeg';
import stripeLogo from '../assets/donate/stripeLogo.png'

// SPONSORSHIP
import desk from '../assets/sponsorship/deskImg.png'
import lunch from '../assets/sponsorship/lunchImg.png'
import materials from '../assets/sponsorship/materialsImg.png'
import uniform from '../assets/sponsorship/uniformImg.png'

// --------------------------- ARTICLES ---------------------------------- //



/* ---------------------------- INNSAMLING ---------------------- */
import classPhoto from '../assets/innsamling/twa-classphoto.png'
import collection from '../assets/innsamling/innsamling-collection.png'
import collection2 from '../assets/innsamling/innsamling-collection-2.png'


export default {

    // PAGES IMAGES
    logoTransparent, logo, boysPlayingChess, happyBoys, insideTheLibrary, workingTogether, happyChildren, kiberaKidBlack, cover, coverMobile,

    // ABOUT US - General
    team,

    // ABOUT US - Kibera
    kibera1, kibera2, kibera3, kibera4, kibera5, kibera6,

    // ABOUT US - Founder
    founder1, founder2, founder3, founder4, founder5, founder6, founder7, founder8, founder9, founder10, founder11, founder12, founder13,

    // ABOUT US - Publications
    publications1,

    // ABOUT US - Partners
    partners1,

    // Get in touch - PARTNERS
    opsahlGruppen, skullerudSkole, wwqa, youcanyole, sos,

    // DONATE
    vippsLogo, paypalLogo, donorBoxLogo, scanVipps, kidLooking, stripeLogo,

    // SPONSORSHIP
    desk, lunch, materials, uniform,

    // INNSAMLING
    classPhoto, collection, collection2
}