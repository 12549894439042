import React from "react";
import { useAuthState } from "react-firebase-hooks/auth"; // If using react-firebase-hooks
import { auth } from "../../../firebase";
import {
  Navbar,
  Footer,
  Side,
  Counter,
  Partners,
  Section,
  MyLink,
  Loading,
} from "../../../components";
// import { articles } from "../../container/Articles/Articles";
import { useArticles } from "../Articles/ArticlesContext";
import { images } from "../../../constants";
// import { is } from "immutable";

const Home = ({ isAdmin }) => {
  console.log("isAdmin in Home:", isAdmin);
  const [user] = useAuthState(auth); // Get the current user
  const { articles, articlesLoading } = useArticles();
  const publishedArticles = articles.filter(
    (article) => article.status === "Published"
  ).length;

  const truncateDescription = (description, maxLength = 380) => {
    if (description.length > maxLength) {
      // Find the last space within the 200 character limit
      const trimmedText = description.substring(0, maxLength);
      const lastSpaceIndex = trimmedText.lastIndexOf(" ");
      return description.substring(0, lastSpaceIndex) + "...";
    }
    return description;
  };

  return (
    <div>
      <Navbar isAdmin={isAdmin} />
      <div className="max1600">
        <img src={images.cover} className="img-homepage cover" />
        <img src={images.coverMobile} className="img-homepage coverMobile" />
      </div>

      <div>
        <div className="row" id="flex">
          <Side />
          <div className="main" id="main">
            <div className="counter-parent">
              <div className="counter-box">
                <Counter
                  header="Sponsored Children"
                  targetNumber={32}
                  icon='<i class="fa-solid fa-school"></i>'
                  description={
                    <>
                      We currently have 32 students in our sponsorship school.
                    </>
                  }
                  isAdmin={isAdmin}
                  firestorePath="counters/sponsoredChildren"
                />
              </div>
              <div className="counter-box">
                <Counter
                  header="Daily Library Users"
                  targetNumber={230}
                  icon='<i class="fa-solid fa-book"></i>'
                  description={
                    <>
                      More than 200 children have direct access to our library
                      on a daily basis.
                    </>
                  }
                  isAdmin={isAdmin}
                  firestorePath="counters/dailyLibraryUsers"
                />
              </div>
              <div className="counter-box">
                <Counter
                  header="Kgs Waste Collected"
                  targetNumber={1500}
                  icon='<i class="fa-solid fa-recycle"></i>'
                  description={
                    <>
                      We have removed more than 1500 kgs of garbage from the
                      community.
                    </>
                  }
                  isAdmin={isAdmin}
                  firestorePath="counters/childrenImpacted"
                />
              </div>

              <div className="counter-box">
                <Counter
                  header="Total Meals Served"
                  targetNumber={3100}
                  icon='<i class="fa-solid fa-utensils"></i>'
                  description={
                    <>
                      Through our programs, we have been able to feed many
                      children.
                    </>
                  }
                  isAdmin={isAdmin}
                  firestorePath="counters/mealsServed"
                />
              </div>
            </div>

            <br />
            <MyLink to="/articles">
              <h2 className="home-newsletter-header">
                <u>Newsletters</u>
              </h2>
            </MyLink>

            {articlesLoading ? (
              <div className="loading-container">
                <div className="loading-animation"></div>
                <div className="loading-text">
                  <Loading />
                </div>
              </div>
            ) : (
              <>
                {articles
                  .filter(
                    (article) => isAdmin || article.status === "Published"
                  )
                  .sort((a, b) => b.id - a.id)
                  .slice(0, 3)
                  .map((article) => (
                    <div key={article.id}>
                      <div className="article" id={article.id}>
                        {isAdmin && article.status === "Draft" && (
                          <div
                            className={`article-status article-status-${article.status.toLowerCase()}`}
                          >
                            <span
                              className={`status-${article.status.toLowerCase()}`}
                            >
                              {article.status}{" "}
                              <i className="fa fa-pencil-alt"></i>
                            </span>
                          </div>
                        )}

                        <Section
                          articleId={article.id}
                          header={article.title}
                          day={article.day}
                          month={article.month}
                          year={article.year}
                          description={truncateDescription(article.description)}
                          img={article.img}
                          showDraftStatus={true}
                          readMore="Read more"
                        />
                      </div>
                      <br />
                    </div>
                  ))}
                <MyLink to="/articles">
                  <div className="article-click goToNews">
                    <p className="readmore">
                      More Newsletters{" "}
                      <i className="fa-solid fa-arrow-right"></i>
                    </p>
                  </div>
                </MyLink>
              </>
            )}
          </div>
        </div>
        <Partners />
        <Footer />
      </div>
      {/* )} */}
    </div>
  );
};

export default Home;
