// Budget.jsx
import React, { useState, useEffect } from "react";
import { db } from "../../../../firebase";
import {
  collection,
  addDoc,
  onSnapshot,
  query,
  where,
  getDocs,
  serverTimestamp,
} from "firebase/firestore";
import BudgetContainer from "./BudgetContainer";
import BudgetSummary from "./BudgetSummary";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function Budget() {
  const [budgets, setBudgets] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [newMonth, setNewMonth] = useState(months[new Date().getMonth()]); // Default to current month
  const [newYear, setNewYear] = useState(new Date().getFullYear()); // Default to current year
  const [creatingBudget, setCreatingBudget] = useState(false);

  useEffect(() => {
    const q = collection(db, "budgets");
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const data = snapshot.docs.map((d) => ({
        id: d.id,
        ...d.data(),
      }));
      setBudgets(data);
    });
    return () => unsubscribe();
  }, []);

  const handleCreateNewMonth = async () => {
    if (creatingBudget) return;

    setCreatingBudget(true);

    try {
      // Query Firestore to check if a budget already exists for the selected month and year
      const budgetQuery = query(
        collection(db, "budgets"),
        where("month", "==", newMonth),
        where("year", "==", newYear)
      );
      const querySnapshot = await getDocs(budgetQuery);

      if (!querySnapshot.empty) {
        alert(`Budget for ${newMonth} ${newYear} already exists.`);
        setCreatingBudget(false);
        return;
      }

      // If no existing budget, create a new one
      await addDoc(collection(db, "budgets"), {
        month: newMonth,
        year: newYear,
        createdAt: serverTimestamp(),
      });

      alert(`Budget for ${newMonth} ${newYear} created successfully.`);
    } catch (error) {
      console.error("Error creating budget:", error);
      alert("Failed to create budget.");
    } finally {
      setCreatingBudget(false);
    }
  };

  return (
    <div style={{ padding: 20 }}>
      {selectedMonth ? (
        <BudgetContainer
          selectedMonth={selectedMonth}
          setSelectedMonth={setSelectedMonth}
        />
      ) : (
        <>
          <h2>Budget Overview</h2>

          <div style={{ marginBottom: 20 }}>
            Create a new budget:{" "}
            <select
              value={newMonth}
              onChange={(e) => setNewMonth(e.target.value)}
            >
              {months.map((month) => (
                <option key={month} value={month}>
                  {month}
                </option>
              ))}
            </select>
            <select
              value={newYear}
              onChange={(e) => setNewYear(parseInt(e.target.value))}
            >
              {[new Date().getFullYear(), new Date().getFullYear() + 1].map(
                (year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                )
              )}
            </select>{" "}
            <button
              className="remark-button blue"
              onClick={handleCreateNewMonth}
              disabled={creatingBudget}
            >
              {creatingBudget ? "Creating..." : "Create Budget"}
            </button>
          </div>

          {budgets.length === 0 && <p>No budgets created yet.</p>}
          {budgets.map((b) => (
            <BudgetSummary
              key={b.id}
              budgetDoc={b}
              setSelectedMonth={setSelectedMonth}
            />
          ))}
        </>
      )}
    </div>
  );
}

export default Budget;
