// SingleAbout.jsx
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { doc, getDoc, updateDoc, deleteDoc } from "firebase/firestore";
import { db, storage } from "../../../firebase";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { Navbar, Footer, Section, MyLink } from "../../../components";
import LoadingIndicator from "../../../components/Loading/LoadingIndicator";
import { useAbout } from "./AboutContext";
import Slideshow from "../../../components/Javascript/Slideshow";

const SingleAbout = ({ isAdmin }) => {
  const { aboutId } = useParams();
  const navigate = useNavigate();

  const [aboutData, setAboutData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);
  const { fetchAbout } = useAbout();
  const [isProcessing, setIsProcessing] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const [slideshowImages, setSlideshowImages] = useState([]);
  const [newSlide, setNewSlide] = useState(null);

  useEffect(() => {
    const fetchAboutPage = async () => {
      try {
        const docRef = doc(db, "aboutPages", aboutId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = { id: docSnap.id, ...docSnap.data() };
          setAboutData(data);
          setSlideshowImages(data.slideshow || []);
        } else {
          setAboutData(null);
        }
      } catch (error) {
        console.error("Error fetching About page:", error);
        setAboutData(null);
      } finally {
        setLoading(false);
      }
    };
    fetchAboutPage();
  }, [aboutId]);

  // -------------------------------
  // Update top-level about fields
  // -------------------------------
  const handleUpdateAbout = async (updatedFields) => {
    if (!aboutId || !aboutData) return;
    try {
      const docRef = doc(db, "aboutPages", aboutId);
      const updates = {
        title: updatedFields.header || aboutData.title,
        description: updatedFields.description || aboutData.description,
        day: updatedFields.day || aboutData.day,
        month: updatedFields.month || aboutData.month,
        year: updatedFields.year || aboutData.year,
      };

      // Remove keys whose values ended up as undefined
      Object.keys(updates).forEach((key) => {
        if (updates[key] === undefined) {
          delete updates[key];
        }
      });

      await updateDoc(docRef, updates);
      console.log("About page updated successfully.");
    } catch (error) {
      console.error("Error updating about page:", error);
    }
  };

  const moveSectionUp = async (sectionId) => {
    if (!aboutData || !aboutData.sections) return;

    const sections = [...aboutData.sections];
    const index = sections.findIndex((sec) => sec.section_id === sectionId);

    // Ensure the section is not the first one
    if (index > 0) {
      // Swap with the previous section
      [sections[index - 1], sections[index]] = [
        sections[index],
        sections[index - 1],
      ];

      try {
        const docRef = doc(db, "aboutPages", aboutId);
        await updateDoc(docRef, { sections });
        setAboutData({ ...aboutData, sections });
      } catch (error) {
        console.error("Error moving section up:", error);
      }
    }
  };

  // Function to move a section down
  const moveSectionDown = async (sectionId) => {
    if (!aboutData || !aboutData.sections) return;

    const sections = [...aboutData.sections];
    const index = sections.findIndex((sec) => sec.section_id === sectionId);

    // Ensure the section is not the last one
    if (index < sections.length - 1) {
      // Swap with the next section
      [sections[index + 1], sections[index]] = [
        sections[index],
        sections[index + 1],
      ];

      try {
        const docRef = doc(db, "aboutPages", aboutId);
        await updateDoc(docRef, { sections });
        setAboutData({ ...aboutData, sections });
      } catch (error) {
        console.error("Error moving section down:", error);
      }
    }
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
    setIsProcessing(true);

    try {
      // Create a unique file name
      const storageRef = ref(
        storage,
        `aboutSlideshow/${Date.now()}_${file.name}`
      );
      // Upload the file
      await uploadBytes(storageRef, file);
      // Get the download URL
      const url = await getDownloadURL(storageRef);
      // Update local state
      const updatedSlideshow = [...slideshowImages, url];
      setSlideshowImages(updatedSlideshow);
      // Update Firestore
      await updateDoc(doc(db, "aboutPages", aboutId), {
        slideshow: updatedSlideshow,
      });
      console.log("Slideshow image added successfully.");
    } catch (error) {
      console.error("Error uploading slideshow image:", error);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleDeleteSlide = async (url) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this slide?"
    );
    if (!confirmDelete) return;

    try {
      setIsProcessing(true);
      // Find the storage reference from the URL
      const storageRef = ref(storage, url);
      // Delete the file from storage
      await deleteObject(storageRef);
      // Update local state
      const updatedSlideshow = slideshowImages.filter((img) => img !== url);
      setSlideshowImages(updatedSlideshow);
      // Update Firestore
      await updateDoc(doc(db, "aboutPages", aboutId), {
        slideshow: updatedSlideshow,
      });
      console.log("Slideshow image deleted successfully.");
    } catch (error) {
      console.error("Error deleting slideshow image:", error);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleUpdateSection = async (updatedSectionFields) => {
    try {
      if (!aboutId || !aboutData) return;
      const docRef = doc(db, "aboutPages", aboutId);
      const docSnap = await getDoc(docRef);

      if (!docSnap.exists()) return;
      const currentSections = docSnap.data().sections || [];

      // Find and update the matching section
      const updatedSections = currentSections.map((section) => {
        if (section.section_id === updatedSectionFields.sectionId) {
          return {
            ...section,
            section_title:
              updatedSectionFields.sectionTitle ?? section.section_title,
            section_description:
              updatedSectionFields.sectionDescription ??
              section.section_description,
          };
        }
        return section;
      });

      await updateDoc(docRef, { sections: updatedSections });
      console.log("About page section updated successfully.");

      setAboutData((prev) => ({
        ...prev,
        sections: updatedSections,
      }));
      // 3) Also refresh the context items if you'd like them in sync:
      await fetchAbout();
    } catch (error) {
      console.error("Error updating about section:", error);
    }
  };

  // -------------------------------
  // Add a new empty section
  // -------------------------------
  const addNewSection = async () => {
    if (!aboutData) return;
    const sections = aboutData.sections || [];

    // Determine a new section_id
    const newSectionId =
      sections.length > 0
        ? Math.max(...sections.map((s) => s.section_id)) + 1
        : 1;

    const newSection = {
      section_id: newSectionId,
      section_title: "",
      section_description: "New Section Description",
      section_image: "",
    };

    const updatedSections = [...sections, newSection];
    setAboutData({ ...aboutData, sections: updatedSections });

    // Update in Firestore
    try {
      const docRef = doc(db, "aboutPages", aboutId);
      await updateDoc(docRef, { sections: updatedSections });
      console.log("New section added successfully.");
    } catch (error) {
      console.error("Error adding new section:", error);
    }
  };

  // -------------------------------
  // Delete a specific section
  // -------------------------------
  const deleteSection = async (sectionId) => {
    if (!aboutData || !aboutData.sections) return;
    const updatedSections = aboutData.sections.filter(
      (sec) => sec.section_id !== sectionId
    );

    setAboutData({ ...aboutData, sections: updatedSections });

    // Update in Firestore
    try {
      const docRef = doc(db, "aboutPages", aboutId);
      await updateDoc(docRef, { sections: updatedSections });
      console.log("Section deleted successfully.");
    } catch (error) {
      console.error("Error deleting section:", error);
    }
  };

  // -------------------------------
  // Delete the entire about page
  // -------------------------------

  const toggleAboutStatus = async () => {
    if (!aboutData || !aboutId) return;
    const newStatus = aboutData.status === "Published" ? "Draft" : "Published";
    try {
      setIsProcessing(true);
      setIsUpdating(true);
      await updateDoc(doc(db, "aboutPages", aboutId), { status: newStatus });
      setTimeout(() => {
        setAboutData((prev) => ({ ...prev, status: newStatus }));
        setIsProcessing(false);
        setIsUpdating(false);
      }, 1500);
    } catch (error) {
      console.error("Error updating about status:", error);
    }
  };

  const handleDeleteAbout = async () => {
    if (!aboutId) return;

    const confirmDelete = window.confirm(
      "Are you sure you want to delete this page? This action cannot be reversed."
    );
    if (!confirmDelete) return;

    try {
      setIsDeleting(true);

      await deleteDoc(doc(db, "aboutPages", aboutId));

      console.log("About page deletion logic here...");
      // Then:
      setTimeout(() => {
        navigate("/about");
      }, 2000);
    } catch (error) {
      console.error("Error deleting about page:", error);
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <>
      <Navbar isAdmin={isAdmin} />
      <div className="row" id="flex">
        <div className="main max-1000">
          <MyLink to="/about">
            <div className="div-back">
              <p className="allNewsLetters">
                <i className="fa-solid fa-arrow-left"></i> Back to About
              </p>
            </div>
          </MyLink>

          {isAdmin && (
            <span style={{ marginBottom: "1rem" }}>
              {!isUpdating && aboutData?.status && (
                <span className={`status-${aboutData.status.toLowerCase()}`}>
                  {aboutData.status === "Published" ? (
                    <>
                      {aboutData.status} <i className="fa fa-check-circle" />
                    </>
                  ) : (
                    <>
                      {aboutData.status} <i className="fa fa-pencil-alt" />
                    </>
                  )}
                </span>
              )}
              <span>
                <span
                  onClick={toggleAboutStatus}
                  className="div-readmore status"
                  disabled={isUpdating}
                >
                  {aboutData?.status === "Published" ? (
                    <>
                      <i class="fa-solid fa-download"></i> Unpublish{" "}
                      {isUpdating && (
                        <span className="rolling-loading-animation small blue"></span>
                      )}
                    </>
                  ) : (
                    <>
                      <i class="fa-solid fa-upload"></i> Publish{" "}
                      {isUpdating && (
                        <span className="rolling-loading-animation small blue"></span>
                      )}
                    </>
                  )}
                </span>
                <span
                  className="div-readmore status"
                  onClick={handleDeleteAbout}
                  disabled={isDeleting || isUpdating}
                >
                  <i class="fa-solid fa-trash-can"></i> Delete{" "}
                  {isDeleting && (
                    <span className="rolling-loading-animation small blue"></span>
                  )}
                </span>
              </span>
            </span>
          )}

          {loading ? (
            <LoadingIndicator />
          ) : (
            <div className="article">
              {isAdmin && (
                <p className="small-info">
                  <i className="fa-solid fa-user-pen"></i> You are in Edit mode.
                  Click the green "ADMIN" toggle in the navbar to see how it
                  appears to users.
                </p>
              )}
              <Section
                header={aboutData.title}
                description={aboutData.description}
                img={aboutData.img}
                day={aboutData.day}
                month={aboutData.month}
                year={aboutData.year}
                isAbout
                aboutId={aboutData.id}
                isAdmin={isAdmin}
                onUpdateArticle={handleUpdateAbout}
              />

              {aboutData.sections &&
                aboutData.sections.map((section, index) => (
                  <Section
                    key={section.section_id}
                    isAbout
                    aboutId={aboutData.id}
                    sectionId={section.section_id}
                    sectionTitle={section.section_title}
                    sectionDescription={section.section_description}
                    img={section.section_image}
                    isAdmin={isAdmin}
                    onUpdateSection={handleUpdateSection}
                    onDeleteSection={() => deleteSection(section.section_id)}
                    canMoveUp={index > 0} // Can move up if not the first sub-section
                    canMoveDown={index < aboutData.sections.length - 1} // Can move down if not the last
                    moveUp={() => moveSectionUp(section.section_id)}
                    moveDown={() => moveSectionDown(section.section_id)}
                  />
                ))}
            </div>
          )}
          <br />
          {isAdmin && (
            <>
              <div className="article">
                <span
                  className="div-readmore addSection"
                  onClick={addNewSection}
                >
                  Add New Section <i class="fa-solid fa-plus"></i>
                </span>
              </div>
              <br />
            </>
          )}
          {slideshowImages.length > 0 && (
            <>
              <div className="article">
                <Slideshow slides={slideshowImages} dots={slideshowImages} />
              </div>
              <br />
            </>
          )}

          {isAdmin && (
            <div className="article">
              {isAdmin && (
                <div className="slideshow-admin">
                  Upload slideshow image(s):
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageUpload}
                  />
                  {isProcessing && (
                    <span className="loading-spinner">Uploading...</span>
                  )}
                </div>
              )}
              <br />
              {isAdmin && (
                <div className="slideshow-thumbnails">
                  {slideshowImages.map((img, index) => (
                    <div key={index} className="thumbnail">
                      <img src={img} alt={`Slide ${index + 1}`} width="100" />
                      <button
                        onClick={() => handleDeleteSlide(img)}
                        disabled={isProcessing}
                      >
                        Delete
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      <Footer />
    </>
  );
};

export default SingleAbout;
