import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import MyLink from "../MyLink/MyLink";
import AdminSwitch from "../AdminSwitch/AdminSwitch";
import { auth } from "../../firebase";
import { signOut } from "firebase/auth";
import { useAuth } from "../../AuthProvider";
import { PageCountsContext } from "../../container/Pages/Intranet/General/PageCountsContext";
import { images } from "../../constants";
import "./Navbar.css";

const Navbar = () => {
  const { isAdmin, userData, setUserData } = useAuth();
  const pageCounts = useContext(PageCountsContext);

  const [isMenuOpen, setMenuOpen] = useState(false);
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const location = useLocation();
  const isMobile = window.innerWidth <= 670;

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUserLoggedIn(!!user);
      if (user && user.email) {
        setUserEmail(user.email.split("@")[0]);
      }
    });
    return unsubscribe;
  }, []);

  const isActive = (item) => {
    const currentPath = location.pathname;
    return currentPath === item.path || currentPath.includes(item.path)
      ? "active"
      : "";
  };

  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
  };

  const totalPageCount = Object.values(pageCounts).reduce(
    (sum, count) => sum + count,
    0
  );

  const navItems = [
    { name: "Home", path: "/" },
    {
      name: "Projects",
      path: "/projects",
    },
    {
      name: "Our School",
      path: "/school",
    },
    {
      name: "About Us",
      path: "/about",
    },
    { name: "Contact", path: "/contact" },
    { name: "Support", path: "/support" },
  ];

  const handleLogout = async () => {
    try {
      await signOut(auth);
      setUserData(null);
    } catch (error) {
      console.error("Logout Error:", error);
    }
  };

  return (
    <header className="navbar-container">
      <nav className="navbar" onClick={toggleMenu}>
        <div className="navbar-brand">
          <img
            src={images.logoTransparent}
            alt="Logo"
            className="navbar-logo"
          />
          The Watoto Program
        </div>

        <button className="menu-toggle">
          {isMenuOpen ? (
            <i className="fa fa-times"></i>
          ) : (
            <i className="fa fa-bars"></i>
          )}
        </button>
        <ul className={`nav-links ${isMenuOpen ? "open" : ""}`}>
          {navItems.map((item) => (
            <li key={item.name} className={`nav-item ${isActive(item)}`}>
              <MyLink to={item.path} className="nav-link">
                {item.name}
              </MyLink>
            </li>
          ))}

          {userData && (userData.role === "Staff" || isAdmin) && (
            <li className="nav-item intranet-styling">
              <MyLink to="/intranet" className="nav-link">
                <i className="fa-solid fa-chart-line"></i> INTRANET{" "}
                {totalPageCount > 0 && (
                  <span className="request-count navbar">
                    <i className="fa-regular fa-bell"></i> {totalPageCount}
                  </span>
                )}
              </MyLink>
            </li>
          )}
          {(!userData || (userData.role !== "Staff" && !isAdmin)) && (
            <li className="nav-item sponsor-link">
              <MyLink to="/sponsorship" className="nav-link">
                <i className="fa-solid fa-child"></i> BECOME A SPONSOR
              </MyLink>
            </li>
          )}

          <li className="nav-item admin-switch">
            <AdminSwitch isAdmin={isAdmin} />
          </li>

          {userLoggedIn && (
            <li className="nav-item">
              <button onClick={handleLogout} className="logout-btn">
                Logout
              </button>
            </li>
          )}
        </ul>
      </nav>
    </header>
  );
};

export default Navbar;
